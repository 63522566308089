import { push } from "connected-react-router";
import { all, call, put, takeEvery } from "redux-saga/effects";
import ajax from "../../../util/api";
import * as constants from "../../../constants/repression/places";
import { ActionWithPayloadBase } from "../../../actions/actionWithPayloadBase";
import { filter } from "lodash";
import PlaceModel from "../../../models/repression/Places/PlaceModel";
import PlaceHomepageFrontModel from "../../../models/repression/Places/PlaceHomepageFrontModel";

function* getPlacesHomepage() {
    try {
        const homepage = yield call(ajax.get, "/places-homepage?include=workers&append=map_base_url");
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "content",
            payload: new PlaceHomepageFrontModel(homepage.data)
        });
    } catch (error) {
        if (error.response) {
            switch (error.status) {
                case 404:
                    yield put(push("/404"));
                    break;
                default:
                    yield put(push("/500"));
                    break;
            }
        } else {
            yield put(push("/500"));
        }
    } finally {
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "isLoading",
            payload: false
        });
    }
}

function* getPlaces(action: ActionWithPayloadBase<Array<number>>) {
    try {
        let urlToCall = "/places?include=category&filter[is_published]=true";
        if (action.payload && filter(action.payload, el => el !== 0).length > 0) {
            urlToCall += '&filter[places_category_id]=' + filter(action.payload, el => el !== 0).join(',');
        }
        const places = yield call(ajax.get, urlToCall);
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "places",
            payload: (places.data && places.data.data.map((el: any) => new PlaceModel(el))) ?? []
        });
    } catch (error) {
        console.log(error);
        if (error.response) {
            switch (error.status) {
                case 404:
                    yield put(push("/404"));
                    break;
                default:
                    yield put(push("/500"));
                    break;
            }
        } else {
            yield put(push("/500"));
        }
    } finally {
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "isLoading",
            payload: false
        });
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.GET_PLACES_HOMEPAGE, getPlacesHomepage),
        takeEvery(constants.GET_PLACES, getPlaces)
    ]);
}