import { RouterState } from "connected-react-router";
import { Settings, getDefaultSettings } from "../reducers/settings";
import { Auth, getDefaultAuth } from "../reducers/auth";
import { Forms, getDefaultForms } from "../reducers/forms";
import { Tables, getDefaultTables } from "../reducers/tables";
import { Administration, getDefaultAdministration } from "../reducers/administration";
import { getDefaultPhotoGallery, PhotoGallery } from "../reducers/photoGallery";
import { getDefaultVideoGallery, VideoGallery } from "../reducers/videoGallery";
import { DocumentViewer, getDefaultDocumentViewer } from "../reducers/documentViewer";
import { getDefaultRepression, Repression } from "../reducers/repression";
import { Dictionaries, dictionariesDefaultState } from "../reducers/dictionaries";
import { getDefaultLanding, Landing } from "../reducers/landing";
import { Search } from "../reducers/search";
import { getDefaultSearch } from "../reducers/search";
import { About, getDefaultAbout } from "../reducers/about";
import { Contact, getDefaultContact } from "../reducers/contact";
import { getDefaultTerms, Terms } from "../reducers/terms";
import { getDefaultPrivacy, Privacy } from "../reducers/privacy";

export interface AppState {
    router?: RouterState;
    settings: Settings;
    auth: Auth;
    forms: Forms;
    tables: Tables;
    administration: Administration;
    photoGallery: PhotoGallery;
    videoGallery: VideoGallery;
    documentViewer: DocumentViewer;
    repression: Repression;
    dictionaries: Dictionaries;
    landing: Landing;
    search: Search;
    about: About;
    contact: Contact;
    terms: Terms;
    privacy: Privacy;
};

export const defaultAppState: AppState = {
    settings: getDefaultSettings,
    auth: getDefaultAuth,
    forms: getDefaultForms,
    tables: getDefaultTables,
    administration: getDefaultAdministration,
    photoGallery: getDefaultPhotoGallery,
    videoGallery: getDefaultVideoGallery,
    documentViewer: getDefaultDocumentViewer,
    repression: getDefaultRepression,
    dictionaries: dictionariesDefaultState,
    landing: getDefaultLanding,
    search: getDefaultSearch,
    about: getDefaultAbout,
    contact: getDefaultContact,
    terms: getDefaultTerms,
    privacy: getDefaultPrivacy
};