export default class PlacesHomepageModel {
    content: string;
    map_base: File;
    map_base_url: string;

    constructor(obj?: PlacesHomepageModel) {
        this.content = obj?.content;
        this.map_base = obj?.map_base;
        this.map_base_url = obj?.map_base_url;
    }
}