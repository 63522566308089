import { all, call, put, takeEvery } from "redux-saga/effects";
import login from "./login";
import * as constants from "../../constants/auth";
import { push } from "connected-react-router";
import ajax from "../../util/api";

function* signOut() {
    try {
        yield call(ajax.get, "/auth/logout");
        sessionStorage.removeItem("crtUser");
        yield all([
            put({
                type: constants.SIGNOUT_USER_SUCCESS
            }),
            put(push("/"))
        ]);
    } catch (error) {
        console.log(error);
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.SIGNOUT_USER, signOut),
        login()
    ]);
}