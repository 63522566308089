import { all } from "redux-saga/effects";
import auth from "./auth";
import tables from "./tables";
import administration from "./administration";
import photoGallery from "./photoGallery";
import videoGallery from "./videoGallery";
import documentViewer from "./documentViewer";
import utils from "./utils";
import repression from "./repression";
import dictionaries from "./dictionaries";
import landing from "./landing";
import search from "./search";
import about from "./about";
import contact from "./contact";
import terms from "./terms";
import privacy from "./privacy";

export default function* rootSaga() {
    yield all([
        auth(),
        tables(),
        administration(),
        photoGallery(),
        utils(),
        videoGallery(),
        documentViewer(),
        repression(),
        dictionaries(),
        landing(),
        search(),
        about(),
        contact(),
        terms(),
        privacy()
    ]);
}
