import PlacesManagementForCreationModel from "./PlacesManagementForCreationModel";

export default class PlacesManagementForUpdateModel extends PlacesManagementForCreationModel {
    id: number;

    constructor(obj?: PlacesManagementForUpdateModel) {
        super(obj?.place_id);
        this.id = obj?.id;
        this.last_name = obj?.last_name;
        this.first_name = obj?.first_name;
        this.position = obj?.position;
        this.start_year = obj?.start_year;
        this.end_year = obj?.end_year;
    }
}