export default class PlacesForCreationModel {
    name: string;
    start_year: number;
    end_year: number;
    places_category_id: number;
    people_at_channel: number;

    constructor(obj?: PlacesForCreationModel) {
        this.name = obj?.name;
        this.start_year = obj?.start_year;
        this.end_year = obj?.end_year;
        this.places_category_id = obj?.places_category_id;
        this.people_at_channel = obj?.people_at_channel;
    }
}