export default interface IDocumentViewer {
    isLoading: boolean;
    value: any;
    error: string | null;
    url: string | null;
}

export const getDefaultGallery: IDocumentViewer = {
    isLoading: false,
    value: null,
    error: null,
    url: null
};