import { PagePhotoForUpdateModel } from "./PagePhotoModel";

export default class RepressionModel {
    introduction: string;
    content: string;
    photos: Array<PagePhotoForUpdateModel>;
    quote_video: File;
    quote_video_url: string;

    constructor(obj?: RepressionModel) {
        this.introduction = obj?.introduction;
        this.content = obj?.content;
        this.photos = obj?.photos;
        this.quote_video = obj?.quote_video;
        this.quote_video_url = obj?.quote_video_url;
    }
}