import { push } from "connected-react-router";
import { all, call, put, takeEvery } from "redux-saga/effects";
import * as constants from "../../constants/landing";
import LandingFrontModel from "../../models/landing/LandingFrontModel";
import LandingThemesModel from "../../models/landing/LandingThemesModel";
import ajax from "../../util/api";

function* getHomepage() {
    try {
        const homepage = yield call(ajax.get, "/landing-homepage?include=photos,quotes");
        const { data: themes } = yield call(ajax.get, "/pages?filter[is_theme]=true");
        const model = new LandingFrontModel(homepage.data);
        model.updateThemes(themes.data && themes.data.map((el: any) => new LandingThemesModel(el)));
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "content",
            payload: model
        });
    } catch (error) {
        if (error.response) {
            switch (error.status) {
                case 404:
                    yield put(push("/404"));
                    break;
                default:
                    yield put(push("/500"));
                    break;
            }
        } else {
            yield put(push("/500"));
        }
    } finally {
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "isLoading",
            payload: false
        });
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.GET_HOMEPAGE, getHomepage)
    ]);
}