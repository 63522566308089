import { ActionWithDataPathBase } from "../../../actionWithDataPathBase";

import * as constants from "../../../../constants/administration/core/people";
import { ActionWithCallback } from "../../../actionWithCallback";
import { ActionWithPayloadBase } from "../../../actionWithPayloadBase";

export function changeValue(dataPath: string, payload: any): ActionWithDataPathBase<any> {
    return {
        type: constants.CHANGE_VALUE,
        dataPath,
        payload
    };
}

export function initAdd(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.INIT_ADD,
        dataPath,
        payload
    };
}

export function submitAdd(dataPath: string, payload: ActionWithCallback<null>): ActionWithDataPathBase<ActionWithCallback<null>> {
    return {
        type: constants.ADD_SUBMIT,
        dataPath,
        payload
    };
}

export function initEdit(dataPath: string, payload: number): ActionWithDataPathBase<number> {
    return {
        type: constants.INIT_EDIT,
        dataPath,
        payload
    };
}

export function submitEdit(dataPath: string, payload: ActionWithCallback<number>): ActionWithDataPathBase<ActionWithCallback<number>> {
    return {
        type: constants.EDIT_SUBMIT,
        dataPath,
        payload
    };
}

export function submitDeleteAvatar(dataPath: string, payload: number): ActionWithDataPathBase<number> {
    return {
        type: constants.DELETE_AVATAR,
        dataPath,
        payload
    };
}

export function submitDeleteStory(dataPath: string, payload: number): ActionWithDataPathBase<number> {
    return {
        type: constants.DELETE_STORY,
        dataPath,
        payload
    };
}

export function initDelete(payload: number): ActionWithPayloadBase<number> {
    return {
        type: constants.INIT_DELETE,
        payload
    };
}

export function submitDelete(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.DELETE_SUBMIT,
        dataPath,
        payload
    };
}

export function initPhotoAdd(dataPath: string, payload: number): ActionWithDataPathBase<number> {
    return {
        type: constants.INIT_PHOTO_ADD,
        dataPath,
        payload
    };
}

export function submitPhotoAdd(dataPath: string, payload: ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>): ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>> {
    return {
        type: constants.ADD_PHOTO_SUBMIT,
        dataPath,
        payload
    };
}

export function initPhotosEdit(dataPath: string, payload: {
    people_id: number;
    id: number
}): ActionWithDataPathBase<{
    people_id: number;
    id: number
}> {
    return {
        type: constants.INIT_PHOTO_EDIT,
        dataPath,
        payload
    };
}

export function submitPhotosEdit(dataPath: string, payload: ActionWithCallback<{
    id: number;
    people_id: number;
    tableDataPath: string;
}>): ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    people_id: number;
    tableDataPath: string;
}>> {
    return {
        type: constants.EDIT_PHOTO_SUBMIT,
        dataPath,
        payload
    };
}

export function initPhotosDelete(payload: {
    people_id: number;
    id: number
}): ActionWithPayloadBase<{
    people_id: number;
    id: number
}> {
    return {
        type: constants.INIT_PHOTO_DELETE,
        payload
    };
}

export function submitPhotosDelete(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.DELETE_PHOTO_SUBMIT,
        dataPath,
        payload
    };
}

//video
export function initVideoAdd(dataPath: string, payload: number): ActionWithDataPathBase<number> {
    return {
        type: constants.INIT_VIDEO_ADD,
        dataPath,
        payload
    };
}

export function submitVideoAdd(dataPath: string, payload: ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>): ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>> {
    return {
        type: constants.ADD_VIDEO_SUBMIT,
        dataPath,
        payload
    };
}

export function initVideoEdit(dataPath: string, payload: {
    people_id: number;
    id: number
}): ActionWithDataPathBase<{
    people_id: number;
    id: number
}> {
    return {
        type: constants.INIT_VIDEO_EDIT,
        dataPath,
        payload
    };
}

export function submitVideoEdit(dataPath: string, payload: ActionWithCallback<{
    id: number;
    people_id: number;
    tableDataPath: string;
}>): ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    people_id: number;
    tableDataPath: string;
}>> {
    return {
        type: constants.EDIT_VIDEO_SUBMIT,
        dataPath,
        payload
    };
}

export function initVideoDelete(payload: {
    people_id: number;
    id: number
}): ActionWithPayloadBase<{
    people_id: number;
    id: number
}> {
    return {
        type: constants.INIT_VIDEO_DELETE,
        payload
    };
}

export function submitVideoDelete(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.DELETE_VIDEO_SUBMIT,
        dataPath,
        payload
    };
}

export function uplodadDocument(dataPath: string, payload: {
    id: number;
    document: File;
}): ActionWithDataPathBase<{
    id: number;
    document: File;
}> {
    return {
        type: constants.UPLOAD_DOCUMENT,
        dataPath,
        payload
    };
}

export function deleteDocument(dataPath: string, payload: number): ActionWithDataPathBase<number> {
    return {
        type: constants.DELETE_DOCUMENT,
        dataPath,
        payload
    };
}

export function getHomepage(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.GET_HOMEPAGE,
        dataPath,
        payload
    };
}

export function saveHomepage(dataPath: string, payload: ActionWithCallback<null> = null): ActionWithDataPathBase<ActionWithCallback<null>> {
    return {
        type: constants.SAVE_HOMEPAGE,
        dataPath,
        payload
    };
}

export type PeopleActions = ReturnType<
    typeof changeValue |
    typeof initAdd |
    typeof submitAdd |
    typeof initEdit |
    typeof submitEdit |
    typeof initDelete |
    typeof submitDelete |
    typeof initPhotoAdd |
    typeof submitPhotoAdd |
    typeof initPhotosEdit |
    typeof submitPhotosEdit |
    typeof initPhotosDelete |
    typeof submitPhotosDelete |
    typeof initVideoAdd |
    typeof submitVideoAdd |
    typeof initVideoEdit |
    typeof submitVideoEdit |
    typeof initVideoDelete |
    typeof submitVideoDelete |
    typeof uplodadDocument |
    typeof getHomepage |
    typeof saveHomepage
>