import InstrumentsCategories from "../../../../enums/core/InstrumentsCategories";

export default class InstrumentForCreationModel {
    last_name: string;
    first_name: string;
    category_id: InstrumentsCategories;

    constructor(category: InstrumentsCategories) {
        this.category_id = category;
    }
}