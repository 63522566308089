export default class PlaceCategoryModel {
    id: number;
    name: string;
    icon_url: string;
    svg: string;
    color: string;
    photo_url: string;
    photo_path: string;

    constructor(obj?: PlaceCategoryModel) {
        this.id = obj?.id;
        this.name = obj?.name;
        this.icon_url = obj?.icon_url;
        this.svg = obj?.svg;
        this.color = obj?.color;
        this.photo_url = obj?.photo_url;
        this.photo_path = obj?.photo_path;
    }
}