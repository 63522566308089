const MODULE = "ADMINISTRATION_PAGES_";

export const CHANGE_VALUE = `${MODULE}CHANGE_VALUE`;
export type CHANGE_VALUE = typeof CHANGE_VALUE;

export const GET_REPRESSION = `${MODULE}GET_REPRESSION`;
export type GET_REPRESSION = typeof GET_REPRESSION;

export const SAVE_REPRESSION = `${MODULE}SAVE_REPRESSION`;
export type SAVE_REPRESSION = typeof SAVE_REPRESSION;

export const GET_LANDING = `${MODULE}GET_LANDING`;
export type GET_LANDING = typeof GET_LANDING;

export const SAVE_LANDING = `${MODULE}SAVE_LANDING`;
export type SAVE_LANDING = typeof SAVE_LANDING;

export const GET_ABOUT = `${MODULE}GET_ABOUT`;
export type GET_ABOUT = typeof GET_ABOUT;

export const SAVE_ABOUT = `${MODULE}SAVE_ABOUT`;
export type SAVE_ABOUT = typeof SAVE_ABOUT;

export const GET_TERMS = `${MODULE}GET_TERMS`;
export type GET_TERMS = typeof GET_TERMS;

export const SAVE_TERMS = `${MODULE}SAVE_TERMS`;
export type SAVE_TERMS = typeof SAVE_TERMS;

export const GET_PRIVACY = `${MODULE}GET_PRIVACY`;
export type GET_PRIVACY = typeof GET_TERMS;

export const SAVE_PRIVACY = `${MODULE}SAVE_PRIVACY`;
export type SAVE_PRIVACY = typeof SAVE_PRIVACY;

export const INIT_PHOTO_ADD = `${MODULE}INIT_PHOTO_ADD`;
export type INIT_PHOTO_ADD = typeof INIT_PHOTO_ADD;

export const ADD_PHOTO_SUBMIT = `${MODULE}ADD_PHOTO_SUBMIT`;
export type ADD_PHOTO_SUBMIT = typeof ADD_PHOTO_SUBMIT;

export const INIT_PHOTO_EDIT = `${MODULE}INIT_PHOTO_EDIT`;
export type INIT_PHOTO_EDIT = typeof INIT_PHOTO_EDIT;

export const EDIT_PHOTO_SUBMIT = `${MODULE}EDIT_PHOTO_SUBMIT`;
export type EDIT_PHOTO_SUBMIT = typeof EDIT_PHOTO_SUBMIT;

export const INIT_PHOTO_DELETE = `${MODULE}INIT_PHOTO_DELETE`;
export type INIT_PHOTO_DELETE = typeof INIT_PHOTO_DELETE;

export const DELETE_PHOTO_SUBMIT = `${MODULE}DELETE_PHOTO_SUBMIT`;
export type DELETE_PHOTO_SUBMIT = typeof DELETE_PHOTO_SUBMIT;

export const INIT_QUOTE_ADD = `${MODULE}INIT_QUOTE_ADD`;
export type INIT_QUOTE_ADD = typeof INIT_QUOTE_ADD;

export const ADD_QUOTE_SUBMIT = `${MODULE}ADD_QUOTE_SUBMIT`;
export type ADD_QUOTE_SUBMIT = typeof ADD_QUOTE_SUBMIT;

export const INIT_QUOTE_EDIT = `${MODULE}INIT_QUOTE_EDIT`;
export type INIT_QUOTE_EDIT = typeof INIT_QUOTE_EDIT;

export const EDIT_QUOTE_SUBMIT = `${MODULE}EDIT_QUOTE_SUBMIT`;
export type EDIT_QUOTE_SUBMIT = typeof EDIT_QUOTE_SUBMIT;

export const INIT_QUOTE_DELETE = `${MODULE}INIT_QUOTE_DELETE`;
export type INIT_QUOTE_DELETE = typeof INIT_QUOTE_DELETE;

export const DELETE_QUOTE_SUBMIT = `${MODULE}DELETE_QUOTE_SUBMIT`;
export type DELETE_QUOTE_SUBMIT = typeof DELETE_QUOTE_SUBMIT;