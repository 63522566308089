import { ActionWithDataPathBase } from "../../../actionWithDataPathBase";
import * as constants from "../../../../constants/administration/core/instruments";
import { ActionWithCallback } from "../../../actionWithCallback";
import InstrumentsCategories from "../../../../enums/core/InstrumentsCategories";
import { ActionWithPayloadBase } from "../../../actionWithPayloadBase";

export function changeValue(dataPath: string, payload: any): ActionWithDataPathBase<any> {
    return {
        type: constants.CHANGE_VALUE,
        dataPath,
        payload
    };
}

export function initAdd(dataPath: string, payload: InstrumentsCategories): ActionWithDataPathBase<InstrumentsCategories> {
    return {
        type: constants.INIT_ADD,
        dataPath,
        payload
    };
}

export function submitAdd(dataPath: string, payload: ActionWithCallback<InstrumentsCategories>): ActionWithDataPathBase<ActionWithCallback<InstrumentsCategories>> {
    return {
        type: constants.ADD_SUBMIT,
        dataPath,
        payload
    };
}

export function initEdit(dataPath: string, payload: number): ActionWithDataPathBase<number> {
    return {
        type: constants.INIT_EDIT,
        dataPath,
        payload
    };
}

export function submitEdit(dataPath: string, payload: ActionWithCallback<number>): ActionWithDataPathBase<ActionWithCallback<number>> {
    return {
        type: constants.EDIT_SUBMIT,
        dataPath,
        payload
    };
}

export function initDelete(payload: {
    id: number,
    category: InstrumentsCategories
}): ActionWithPayloadBase<{
    id: number,
    category: InstrumentsCategories
}> {
    return {
        type: constants.INIT_DELETE,
        payload
    };
}

export function submitDelete(dataPath: string, payload: InstrumentsCategories): ActionWithDataPathBase<InstrumentsCategories> {
    return {
        type: constants.DELETE_SUBMIT,
        dataPath,
        payload
    };
}

export function initPositionsAdd(dataPath: string, payload: number): ActionWithDataPathBase<number> {
    return {
        type: constants.INIT_POSITIONS_ADD,
        dataPath,
        payload
    };
}

export function submitPositionsAdd(dataPath: string, payload: ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>): ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>> {
    return {
        type: constants.ADD_POSITIONS_SUBMIT,
        dataPath,
        payload
    };
}

export function initPositionsEdit(dataPath: string, payload: {
    instrument_id: number;
    id: number
}): ActionWithDataPathBase<{
    instrument_id: number;
    id: number
}> {
    return {
        type: constants.INIT_POSITIONS_EDIT,
        dataPath,
        payload
    };
}

export function submitPositionsEdit(dataPath: string, payload: ActionWithCallback<{
    id: number;
    instrument_id: number;
    tableDataPath: string;
}>): ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    instrument_id: number;
    tableDataPath: string;
}>> {
    return {
        type: constants.EDIT_POSITIONS_SUBMIT,
        dataPath,
        payload
    };
}

export function initPositionsDelete(payload: {
    instrument_id: number;
    id: number
}): ActionWithPayloadBase<{
    instrument_id: number;
    id: number
}> {
    return {
        type: constants.INIT_POSITIONS_DELETE,
        payload
    };
}

export function submitPositionsDelete(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.DELETE_POSITIONS_SUBMIT,
        dataPath,
        payload
    };
}

export function getHomepage(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.GET_HOMEPAGE,
        dataPath,
        payload
    };
}

export function saveHomepage(dataPath: string, payload: ActionWithCallback<null> = null): ActionWithDataPathBase<ActionWithCallback<null>> {
    return {
        type: constants.SAVE_HOMEPAGE,
        dataPath,
        payload
    };
}

export type InstrumentsActions = ReturnType<
    typeof changeValue |
    typeof initAdd |
    typeof submitAdd |
    typeof initEdit |
    typeof submitEdit |
    typeof initDelete |
    typeof submitDelete |
    typeof initPositionsAdd |
    typeof submitPositionsAdd |
    typeof initPositionsEdit |
    typeof submitPositionsEdit |
    typeof initPositionsDelete |
    typeof submitPositionsDelete |
    typeof getHomepage |
    typeof saveHomepage
>;