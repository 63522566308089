import InstrumentForCreationModel from "./InstrumentForCreationModel";

export default class InstrumentForUpdateModel extends InstrumentForCreationModel {
    id: number;
    is_published: boolean;

    constructor(obj?: InstrumentForUpdateModel) {
        super(obj?.category_id);

        this.last_name = obj?.last_name;
        this.first_name = obj?.first_name;
        this.id = obj?.id;
        this.is_published = obj?.is_published ?? false;
    }
}