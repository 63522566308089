import { number, string } from "yup"

export default class LandingThemesModel {
    id: number;
    quote_video_url: string;

    constructor(obj?: LandingThemesModel) {
        this.id = obj?.id;
        this.quote_video_url = obj?.quote_video_url;
    }
}