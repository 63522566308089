const MODULE = "ADMINISTRATION_CORE_PEOPLE_";

export const CHANGE_VALUE = `${MODULE}CHANGE_VALUE`;
export type CHANGE_VALUE = typeof CHANGE_VALUE;

export const INIT_ADD = `${MODULE}INIT_ADD`;
export type INIT_ADD = typeof INIT_ADD;

export const ADD_SUBMIT = `${MODULE}ADD_SUBMIT`;
export type ADD_SUBMIT = typeof ADD_SUBMIT;

export const INIT_EDIT = `${MODULE}INIT_EDIT`;
export type INIT_EDIT = typeof INIT_EDIT;

export const EDIT_SUBMIT = `${MODULE}EDIT_SUBMIT`;
export type EDIT_SUBMIT = typeof EDIT_SUBMIT;

export const INIT_DELETE = `${MODULE}INIT_DELETE`;
export type INIT_DELETE = typeof INIT_DELETE;

export const DELETE_AVATAR = `${MODULE}DELETE_AVATAR`;
export type DELETE_AVATAR = typeof DELETE_AVATAR;

export const DELETE_STORY = `${MODULE}DELETE_STORY`;
export type DELETE_STORY = typeof DELETE_STORY;

export const DELETE_SUBMIT = `${MODULE}DELETE_SUBMIT`;
export type DELETE_SUBMIT = typeof DELETE_SUBMIT;

export const INIT_IMPRISONMENTS_ADD = `${MODULE}INIT_IMPRISONMENTS_ADD`;
export type INIT_IMPRISONMENTS_ADD = typeof INIT_IMPRISONMENTS_ADD;

export const ADD_IMPRISONMENTS_SUBMIT = `${MODULE}ADD_IMPRISONMENTS_SUBMIT`;
export type ADD_IMPRISONMENTS_SUBMIT = typeof ADD_IMPRISONMENTS_SUBMIT;

export const INIT_IMPRISONMENTS_EDIT = `${MODULE}INIT_IMPRISONMENTS_EDIT`;
export type INIT_IMPRISONMENTS_EDIT = typeof INIT_IMPRISONMENTS_EDIT;

export const EDIT_IMPRISONMENTS_SUBMIT = `${MODULE}EDIT_IMPRISONMENTS_SUBMIT`;
export type EDIT_IMPRISONMENTS_SUBMIT = typeof EDIT_IMPRISONMENTS_SUBMIT;

export const INIT_IMPRISONMENTS_DELETE = `${MODULE}INIT_IMPRISONMENTS_DELETE`;
export type INIT_IMPRISONMENTS_DELETE = typeof INIT_IMPRISONMENTS_DELETE;

export const DELETE_IMPRISONMENTS_SUBMIT = `${MODULE}DELETE_IMPRISONMENTS_SUBMIT`;
export type DELETE_IMPRISONMENTS_SUBMIT = typeof DELETE_IMPRISONMENTS_SUBMIT;

export const INIT_PHOTO_ADD = `${MODULE}INIT_PHOTO_ADD`;
export type INIT_PHOTO_ADD = typeof INIT_PHOTO_ADD;

export const ADD_PHOTO_SUBMIT = `${MODULE}ADD_PHOTO_SUBMIT`;
export type ADD_PHOTO_SUBMIT = typeof ADD_PHOTO_SUBMIT;

export const INIT_PHOTO_EDIT = `${MODULE}INIT_PHOTO_EDIT`;
export type INIT_PHOTO_EDIT = typeof INIT_PHOTO_EDIT;

export const EDIT_PHOTO_SUBMIT = `${MODULE}EDIT_PHOTO_SUBMIT`;
export type EDIT_PHOTO_SUBMIT = typeof EDIT_PHOTO_SUBMIT;

export const INIT_PHOTO_DELETE = `${MODULE}INIT_PHOTO_DELETE`;
export type INIT_PHOTO_DELETE = typeof INIT_PHOTO_DELETE;

export const DELETE_PHOTO_SUBMIT = `${MODULE}DELETE_PHOTO_SUBMIT`;
export type DELETE_PHOTO_SUBMIT = typeof DELETE_PHOTO_SUBMIT;

export const INIT_VIDEO_ADD = `${MODULE}INIT_VIDEO_ADD`;
export type INIT_VIDEO_ADD = typeof INIT_VIDEO_ADD;

export const ADD_VIDEO_SUBMIT = `${MODULE}ADD_VIDEO_SUBMIT`;
export type ADD_VIDEO_SUBMIT = typeof ADD_VIDEO_SUBMIT;

export const INIT_VIDEO_EDIT = `${MODULE}INIT_VIDEO_EDIT`;
export type INIT_VIDEO_EDIT = typeof INIT_VIDEO_EDIT;

export const EDIT_VIDEO_SUBMIT = `${MODULE}EDIT_VIDEO_SUBMIT`;
export type EDIT_VIDEO_SUBMIT = typeof EDIT_VIDEO_SUBMIT;

export const INIT_VIDEO_DELETE = `${MODULE}INIT_VIDEO_DELETE`;
export type INIT_VIDEO_DELETE = typeof INIT_VIDEO_DELETE;

export const DELETE_VIDEO_SUBMIT = `${MODULE}DELETE_VIDEO_SUBMIT`;
export type DELETE_VIDEO_SUBMIT = typeof DELETE_VIDEO_SUBMIT;

export const UPLOAD_DOCUMENT = `${MODULE}UPLOAD_DOCUMENT`;
export type UPLOAD_DOCUMENT = typeof UPLOAD_DOCUMENT;

export const DELETE_DOCUMENT = `${MODULE}DELETE_DOCUMENT`;
export type DELETE_DOCUMENT = typeof DELETE_DOCUMENT;

export const GET_HOMEPAGE = `${MODULE}GET_HOMEPAGE`;
export type GET_HOMEPAGE = typeof GET_HOMEPAGE;

export const SAVE_HOMEPAGE = `${MODULE}SAVE_HOMEPAGE`;
export type SAVE_HOMEPAGE = typeof SAVE_HOMEPAGE;