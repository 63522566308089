const MODULE = "PHOTO_GALLERY_";

export const PUT_NEW_GALLERY = `${MODULE}put-new-gallery`;
export type PUT_NEW_GALLERY = typeof PUT_NEW_GALLERY;
export const INIT_NEW_GALLERY = `${MODULE}INIT_NEW_GALLERY`;
export type INIT_NEW_GALLERY = typeof INIT_NEW_GALLERY;
export const REMOVE_GALLERY = `${MODULE}REMOVE_GALLERY`;
export type REMOVE_GALLERY = typeof REMOVE_GALLERY;

export const GET_REQUEST = `${MODULE}GET_REQUEST`;
export type GET_REQUEST = typeof GET_REQUEST;
export const GET_SUCCESS = `${MODULE}GET_SUCCESS`;
export type GET_SUCCESS = typeof GET_SUCCESS;
export const GET_FAILURE = `${MODULE}GET_FAILURE`;
export type GET_FAILURE = typeof GET_FAILURE;

export const CHANGE_PAGE = `${MODULE}CHANGE_PAGE`;
export type CHANGE_PAGE = typeof CHANGE_PAGE;