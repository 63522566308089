const MODULE = "AUTH_";

export const INIT_URL = `${MODULE}init-url`;
export type INIT_URL = typeof INIT_URL;

export const SET_USER = `${MODULE}set-user`;
export type SET_USER = typeof SET_USER;

export const SIGNOUT_USER = `${MODULE}signout-user`;
export type SIGNOUT_USER = typeof SIGNOUT_USER;

export const SIGNOUT_USER_SUCCESS = `${MODULE}signout-user-success`;
export type SIGNOUT_USER_SUCCESS = typeof SIGNOUT_USER_SUCCESS;