import axios from "axios";
import User from "../models/auth/IUser";

const customAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api`,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
});

customAxios.interceptors.request.use(function (config) {
    const crtUser = sessionStorage.getItem("crtUser");
    if (crtUser) {
        const user: User = JSON.parse(crtUser);
        if (user.access_token) {
            config.headers.Authorization = `Bearer ${user.access_token}`;
        }

    }
    var locale = sessionStorage.getItem("locale");
    if (locale) {
        config.headers['Content-Language'] = locale;
    } else {
        config.headers['Content-Language'] = 'ro';
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default customAxios;