import { ActionWithDataPathBase } from "../../../../actions/actionWithDataPathBase";
import ajax from "../../../../util/api";
import * as constants from "../../../../constants/administration/core/pages";
import * as actions from "../../../../actions/administration/core/pages";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { putNewForm } from "../../../forms";
import RepressionModel from "../../../../models/administration/core/pages/RepressionModel";
import { push } from "connected-react-router";
import { handleFormErrors, handleRequestErrors } from "../../../../util/errorHandling";
import { ActionWithCallback } from "../../../../actions/actionWithCallback";
import { AppState } from "../../../../store/appState";
import get from "lodash/get";
import PagePhotoForCreationModel, { PagePhotoForUpdateModel } from "../../../../models/administration/core/pages/PagePhotoModel";
import * as gridActions from "../../../../actions/photoGallery";
import LandingModel from "../../../../models/administration/core/pages/LandingModel";
import AboutUsModel from "../../../../models/administration/core/pages/AboutModel";
import PageQuoteForCreationModel, { PageQuoteForUpdateModel } from "../../../../models/administration/core/pages/PageQuoteModel";
import * as tableActions from "../../../../actions/tables";

function* getRepression(action: ActionWithDataPathBase<null>) {
    try {
        const { data } = yield call(ajax.get, "/repression-homepage?include=photos");
        yield call(putNewForm, action.dataPath, new RepressionModel(data));
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    yield put(push("/administration/404"));
                    break;
                case 500:
                default:
                    yield put(push("/administration/500"));
                    break;
            }
        }
        const message = handleRequestErrors(error.response);
        yield put(actions.changeValue("errors", message));
    }
}

function* updateRepression(action: ActionWithDataPathBase<ActionWithCallback<null>>) {
    try {
        const formData: RepressionModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        const formToSend = new FormData();
        formToSend.append("content", formData.content);
        formToSend.append("introduction", formData.introduction);
        formToSend.append("quote_video", formData.quote_video);

        yield call(ajax.post, `/repression-homepage`, formToSend);
        if (action.payload.successCallback) {
            yield call(action.payload.successCallback);
        }
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("isLoading", false));
    }
}

function* getLanding(action: ActionWithDataPathBase<null>) {
    try {
        const { data } = yield call(ajax.get, "/landing-homepage");
        yield call(putNewForm, action.dataPath, new LandingModel(data));
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    yield put(push("/administration/404"));
                    break;
                case 500:
                default:
                    yield put(push("/administration/500"));
                    break;
            }
        }
        const message = handleRequestErrors(error.response);
        yield put(actions.changeValue("errors", message));
    }
}

function* updateLanding(action: ActionWithDataPathBase<ActionWithCallback<null>>) {
    try {
        const formData: LandingModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        const formToSend = new FormData();
        formToSend.append("introduction", formData.introduction);

        yield call(ajax.post, `/landing-homepage`, formToSend);
        if (action.payload.successCallback) {
            yield call(action.payload.successCallback);
        }
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("isLoading", false));
    }
}

function* getAbout(action: ActionWithDataPathBase<null>) {
    try {
        const { data } = yield call(ajax.get, "/about-us");
        yield call(putNewForm, action.dataPath, new AboutUsModel(data));
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    yield put(push("/administration/404"));
                    break;
                case 500:
                default:
                    yield put(push("/administration/500"));
                    break;
            }
        }
        const message = handleRequestErrors(error.response);
        yield put(actions.changeValue("errors", message));
    }
}

function* updateAbout(action: ActionWithDataPathBase<ActionWithCallback<null>>) {
    try {
        const formData: AboutUsModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        yield call(ajax.post, `/about-us`, formData);
        if (action.payload.successCallback) {
            yield call(action.payload.successCallback);
        }
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("isLoading", false));
    }
}

function* getTerms(action: ActionWithDataPathBase<null>) {
    try {
        const { data } = yield call(ajax.get, "/terms");
        yield call(putNewForm, action.dataPath, new AboutUsModel(data));
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    yield put(push("/administration/404"));
                    break;
                case 500:
                default:
                    yield put(push("/administration/500"));
                    break;
            }
        }
        const message = handleRequestErrors(error.response);
        yield put(actions.changeValue("errors", message));
    }
}

function* updateTerms(action: ActionWithDataPathBase<ActionWithCallback<null>>) {
    try {
        const formData: AboutUsModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        yield call(ajax.post, `/terms`, formData);
        if (action.payload.successCallback) {
            yield call(action.payload.successCallback);
        }
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("isLoading", false));
    }
}

function* getPrivacy(action: ActionWithDataPathBase<null>) {
    try {
        const { data } = yield call(ajax.get, "/privacy");
        yield call(putNewForm, action.dataPath, new AboutUsModel(data));
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    yield put(push("/administration/404"));
                    break;
                case 500:
                default:
                    yield put(push("/administration/500"));
                    break;
            }
        }
        const message = handleRequestErrors(error.response);
        yield put(actions.changeValue("errors", message));
    }
}

function* updatePrivacy(action: ActionWithDataPathBase<ActionWithCallback<null>>) {
    try {
        const formData: AboutUsModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        yield call(ajax.post, `/privacy`, formData);
        if (action.payload.successCallback) {
            yield call(action.payload.successCallback);
        }
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("isLoading", false));
    }
}

function* initPhotoAdd(action: ActionWithDataPathBase<number>) {
    yield call(putNewForm, action.dataPath, new PagePhotoForCreationModel(action.payload));
}

function* createPhoto(action: ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>>) {
    try {
        const formData: PagePhotoForCreationModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        const formToSend = new FormData();
        formToSend.append("picture", formData.picture);
        formToSend.append("order_no", formData.order_no.toString());

        yield call(ajax.post, `/pages/${action.payload.data.id}/photos`, formToSend);
        yield call(closePhotosDialogAndRefreshGrid, "photos.add.isOpen", action.payload.data.tableDataPath);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("photos.add.isLoading", false));
    }
}

function* initPhotoEdit(action: ActionWithDataPathBase<{
    page_id: number;
    id: number
}>) {
    try {
        const { data } = yield call(ajax.get, `/pages/${action.payload.page_id}/photos/${action.payload.id}`);
        yield call(putNewForm, action.dataPath, new PagePhotoForUpdateModel(data));
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "photos.edit", message);
    }
}

function* updatePhoto(action: ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    page_id: number;
    tableDataPath: string;
}>>) {
    try {
        const formData: PagePhotoForUpdateModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        const formToSend = new FormData();
        formToSend.append("picture", formData.picture);
        formToSend.append("order_no", formData.order_no.toString());
        formToSend.append("_method", "PUT");

        yield call(ajax.post, `/pages/${action.payload.data.page_id}/photos/${action.payload.data.id}`, formToSend);
        yield call(closePhotosDialogAndRefreshGrid, "photos.edit.isOpen", action.payload.data.tableDataPath);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("photos.edit.isLoading", false));
    }
}

function* deletePhoto(action: ActionWithDataPathBase<string>) {
    try {
        const { id, page_id } = yield select((state: AppState) => get(state, action.payload ?? "state.administration.core.people.photos.delete"));
        yield call(ajax.delete, `/pages/${page_id}/photos/${id}`);
        yield call(closePhotosDialogAndRefreshGrid, "photos.delete.isOpen", action.dataPath);
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "photos.delete", message);
    }
    finally {
        yield put(actions.changeValue("photos.delete.isLoading", false));
    }
}

function* closePhotosDialogAndRefreshGrid(dataPath: string, payload: string) {
    yield all([
        put(actions.changeValue(dataPath, false)),
        put(gridActions.getData(payload))
    ]);
}

// quotes
function* initQuoteAdd(action: ActionWithDataPathBase<number>) {
    yield call(putNewForm, action.dataPath, new PageQuoteForCreationModel(action.payload));
}

function* createQuote(action: ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>>) {
    try {
        const formData: PageQuoteForCreationModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        const formToSend = new FormData();
        formToSend.append("picture", formData.picture);
        formToSend.append("order_no", formData.order_no.toString());
        formToSend.append("quote", formData.quote.toString());
        formToSend.append("author", formData.author.toString());

        yield call(ajax.post, `/pages/${action.payload.data.id}/quotes`, formToSend);
        yield call(closeQuotesDialogAndRefreshGrid, "quotes.add.isOpen", action.payload.data.tableDataPath, action.payload.data.id);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("quotes.add.isLoading", false));
    }
}

function* initQuoteEdit(action: ActionWithDataPathBase<{
    page_id: number;
    id: number
}>) {
    try {
        const { data } = yield call(ajax.get, `/pages/${action.payload.page_id}/quotes/${action.payload.id}`);
        yield call(putNewForm, action.dataPath, new PageQuoteForUpdateModel(data));
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "quotes.edit", message);
    }
}

function* updateQuote(action: ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    page_id: number;
    tableDataPath: string;
}>>) {
    try {
        const formData: PageQuoteForUpdateModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        const formToSend = new FormData();
        formToSend.append("picture", formData.picture);
        formToSend.append("order_no", formData.order_no.toString());
        formToSend.append("quote", formData.quote.toString());
        formToSend.append("author", formData.author.toString());
        formToSend.append("_method", "PUT");

        yield call(ajax.post, `/pages/${action.payload.data.page_id}/quotes/${action.payload.data.id}`, formToSend);
        yield call(closeQuotesDialogAndRefreshGrid, "quotes.edit.isOpen", action.payload.data.tableDataPath, action.payload.data.page_id);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("quotes.edit.isLoading", false));
    }
}

function* deleteQuote(action: ActionWithDataPathBase<string>) {
    try {
        const { id, page_id } = yield select((state: AppState) => state.administration.core.pages.quotes.delete);

        yield call(ajax.delete, `/pages/${page_id}/quotes/${id}`);
        yield call(closeQuotesDialogAndRefreshGrid, "quotes.delete.isOpen", action.dataPath, page_id);
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "quotes.delete", message);
    }
    finally {
        yield put(actions.changeValue("quotes.delete.isLoading", false));
    }
}

function* closeQuotesDialogAndRefreshGrid(dataPath: string, payload: string, page_id: number) {
    yield all([
        put(actions.changeValue(dataPath, false)),
        put(tableActions.getData(payload, {
            url: `/pages/${page_id}/quotes`
        }))
    ]);
}

function* setDialogErrors(basePath: string, message: string) {
    yield all([
        put(actions.changeValue(`${basePath}.errors`, message)),
        put(actions.changeValue(`${basePath}.isOpen`, false))
    ]);
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.GET_REPRESSION, getRepression),
        takeEvery(constants.SAVE_REPRESSION, updateRepression),
        takeEvery(constants.GET_LANDING, getLanding),
        takeEvery(constants.SAVE_LANDING, updateLanding),
        takeEvery(constants.GET_ABOUT, getAbout),
        takeEvery(constants.SAVE_ABOUT, updateAbout),
        takeEvery(constants.GET_TERMS, getTerms),
        takeEvery(constants.SAVE_TERMS, updateTerms),
        takeEvery(constants.GET_PRIVACY, getPrivacy),
        takeEvery(constants.SAVE_PRIVACY, updatePrivacy),
        takeEvery(constants.INIT_PHOTO_ADD, initPhotoAdd),
        takeEvery(constants.ADD_PHOTO_SUBMIT, createPhoto),
        takeEvery(constants.INIT_PHOTO_EDIT, initPhotoEdit),
        takeEvery(constants.EDIT_PHOTO_SUBMIT, updatePhoto),
        takeEvery(constants.DELETE_PHOTO_SUBMIT, deletePhoto),
        takeEvery(constants.INIT_QUOTE_ADD, initQuoteAdd),
        takeEvery(constants.ADD_QUOTE_SUBMIT, createQuote),
        takeEvery(constants.INIT_QUOTE_EDIT, initQuoteEdit),
        takeEvery(constants.EDIT_QUOTE_SUBMIT, updateQuote),
        takeEvery(constants.DELETE_QUOTE_SUBMIT, deleteQuote)
    ]);
}