import produce from "immer";
import { ChangePagePayload, ChangeRowsPerPagePayload, OrderByClickPayload, SearchChangePayload, TablesActions, TablesPayloadBase } from "../../actions/tables";
import set from "lodash/set";
import get from "lodash/get";
import findIndex from "lodash/findIndex";
import isEqual from "lodash/isEqual";
import concat from "lodash/concat";
import find from "lodash/find";
import * as constants from "../../constants/tables";
import { ActionWithDataPathBase } from "../../actions/actionWithDataPathBase";
import { ActionWithPayloadBase } from "../../actions/actionWithPayloadBase";

export interface Tables {
    [key: string]: any;
}

export const getDefaultTables: Tables = {

};

const tables = (state: Tables = getDefaultTables, action: TablesActions) => {
    switch (action.type) {
        case constants.PUT_NEW_TABLE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.GET_REQUEST:
            return produce(state, draft => {
                const { dataPath } = (action as ActionWithDataPathBase<TablesPayloadBase>);
                const table = get(draft, dataPath);
                if (table) {
                    table.isLoading = true;
                    table.values = [];
                    table.error = null;
                }
            });
        case constants.GET_SUCCESS:
            return produce(state, draft => {
                const { dataPath } = (action as ActionWithDataPathBase<TablesPayloadBase>);
                const table = get(draft, dataPath);
                if (table) {
                    table.isLoading = false;
                    table.values = action.payload.response.data;
                    table.total = action.payload.response.total;
                }
            });
        case constants.GET_FAILURE:
            return produce(state, draft => {
                const { dataPath } = (action as ActionWithDataPathBase<TablesPayloadBase>);
                const table = get(draft, dataPath);
                if (table) {
                    table.isLoading = false;
                    table.error = action.payload.message;
                }
            });
        case constants.CHANGE_PAGE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<ChangePagePayload>);
                const table = get(draft, dataPath);
                if (table) {
                    table.crtPage = payload.page;
                }
            });
        case constants.CHANGE_ROWS_PER_PAGE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<ChangeRowsPerPagePayload>);
                const table = get(draft, dataPath);
                if (table) {
                    table.rowsPerPage = payload.rowsPerPage;
                }
            });
        case constants.SELECT_ITEM:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                const table = get(draft, dataPath);
                const stateTable = get(state, dataPath);
                if (table && stateTable) {
                    const existing = findIndex(stateTable.selected, el => isEqual(el, payload.item));
                    if (existing > -1) {
                        table.selected.splice(existing, 1);
                    } else {
                        table.selected = concat(stateTable.selected, payload.item);
                    }
                }
            });
        case constants.ORDER_BY_CLICK:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<OrderByClickPayload>);
                const table = get(draft, dataPath);
                const stateTable = get(state, dataPath);
                if (table && stateTable) {
                    const existing = find(table.orderBy, el => el.key === payload.item);
                    if (existing) {
                        existing.dir = existing.dir === "desc" ? "asc" : "desc";
                    } else {
                        table.orderBy = [
                            {
                                key: payload.item,
                                dir: "asc"
                            }
                        ];
                    }
                }
            });
        case constants.CHANGE_VALUE_AT_PATH:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.SEARCH_CHANGE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<SearchChangePayload>);
                set(draft, `${dataPath}.crtPage`, 1);
                set(draft, `${dataPath}.search`, payload.value);
            });
        case constants.REMOVE_TABLE:
            return produce(state, draft => {
                const { payload } = (action as ActionWithPayloadBase<string>);
                delete draft[payload];
            });
        default:
            return state;
    }
}

export default tables;