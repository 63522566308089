export interface Locale {
    languageId: string;
    locale: string;
    name: string;
    icon: string;
};

let getDefaultLocale: Locale = {
    languageId: "romana",
    locale: "ro",
    name: "Română",
    icon: "ro"
};

const localeFromStorage = sessionStorage.getItem("locale");
if (localeFromStorage) {
    switch (localeFromStorage) {
        case "en":
            getDefaultLocale = {
                languageId: 'english',
                locale: 'en',
                name: 'English',
                icon: 'us'
            };
            break;
        default:
            getDefaultLocale = {
                languageId: "romana",
                locale: "ro",
                name: "Română",
                icon: "ro"
            };
            break;
    }
}

export default getDefaultLocale;