import QuoteModel from "../quotes/QuoteModel";
import RepressionPhotoModel from "./RepressionPhotoModel";

export default class RepressionHomepageModel {
    introduction: string;
    content: string;
    photos: Array<RepressionPhotoModel>;
    quote_video_url: string;
    quotes: Array<QuoteModel>;

    constructor(obj?: RepressionHomepageModel) {
        this.introduction = obj?.introduction;
        this.content = obj?.content;
        this.quote_video_url = obj?.quote_video_url;
        this.quotes = obj?.quotes;

        this.photos = obj?.photos && obj.photos.map((el) => new RepressionPhotoModel(el));
    }
}