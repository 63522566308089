import LandingModel from "../administration/core/pages/LandingModel";
import QuoteModel from "../quotes/QuoteModel";
import LandingThemesModel from "./LandingThemesModel";

export default class LandingFrontModel extends LandingModel {
    themes: Array<LandingThemesModel>;
    quotes: Array<QuoteModel>;

    constructor(obj?: LandingFrontModel) {
        super(obj);
        this.quotes = obj?.quotes;
    }

    public updateThemes(themes: Array<LandingThemesModel>): void {
        this.themes = themes;
    }
}