import PlacesHomepageModel from "../../administration/core/places/PlacesHomepageModel";
import PlaceWorkerModel from "./PlaceWorkerModel";

export default class PlaceHomepageFrontModel extends PlacesHomepageModel {
    workers: Array<PlaceWorkerModel>;
    map_base_url: string;

    constructor(obj?: PlaceHomepageFrontModel) {
        super(obj);
        this.workers = obj?.workers.map((el) => new PlaceWorkerModel(el));
        this.map_base_url = obj?.map_base_url;
    }
}