import { ActionWithDataPathBase } from "../../../actionWithDataPathBase";

import * as constants from "../../../../constants/administration/core/pages";
import { ActionWithCallback } from "../../../actionWithCallback";
import { ActionWithPayloadBase } from "../../../actionWithPayloadBase";

export function changeValue(dataPath: string, payload: any): ActionWithDataPathBase<any> {
    return {
        type: constants.CHANGE_VALUE,
        dataPath,
        payload
    };
}

export function getRepression(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.GET_REPRESSION,
        dataPath,
        payload
    };
}

export function saveRepression(dataPath: string, payload: ActionWithCallback<null> = null): ActionWithDataPathBase<ActionWithCallback<null>> {
    return {
        type: constants.SAVE_REPRESSION,
        dataPath,
        payload
    };
}

export function getLanding(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.GET_LANDING,
        dataPath,
        payload
    };
}

export function saveLanding(dataPath: string, payload: ActionWithCallback<null> = null): ActionWithDataPathBase<ActionWithCallback<null>> {
    return {
        type: constants.SAVE_LANDING,
        dataPath,
        payload
    };
}

export function getAbout(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.GET_ABOUT,
        dataPath,
        payload
    };
}

export function saveAbout(dataPath: string, payload: ActionWithCallback<null> = null): ActionWithDataPathBase<ActionWithCallback<null>> {
    return {
        type: constants.SAVE_ABOUT,
        dataPath,
        payload
    };
}

export function getTerms(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.GET_TERMS,
        dataPath,
        payload
    };
}

export function saveTerms(dataPath: string, payload: ActionWithCallback<null> = null): ActionWithDataPathBase<ActionWithCallback<null>> {
    return {
        type: constants.SAVE_TERMS,
        dataPath,
        payload
    };
}

export function getPrivacy(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.GET_PRIVACY,
        dataPath,
        payload
    };
}

export function savePrivacy(dataPath: string, payload: ActionWithCallback<null> = null): ActionWithDataPathBase<ActionWithCallback<null>> {
    return {
        type: constants.SAVE_PRIVACY,
        dataPath,
        payload
    };
}

export function initPhotoAdd(dataPath: string, payload: number): ActionWithDataPathBase<number> {
    return {
        type: constants.INIT_PHOTO_ADD,
        dataPath,
        payload
    };
}

export function submitPhotoAdd(dataPath: string, payload: ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>): ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>> {
    return {
        type: constants.ADD_PHOTO_SUBMIT,
        dataPath,
        payload
    };
}

export function initPhotosEdit(dataPath: string, payload: {
    page_id: number;
    id: number
}): ActionWithDataPathBase<{
    page_id: number;
    id: number
}> {
    return {
        type: constants.INIT_PHOTO_EDIT,
        dataPath,
        payload
    };
}

export function submitPhotosEdit(dataPath: string, payload: ActionWithCallback<{
    id: number;
    page_id: number;
    tableDataPath: string;
}>): ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    page_id: number;
    tableDataPath: string;
}>> {
    return {
        type: constants.EDIT_PHOTO_SUBMIT,
        dataPath,
        payload
    };
}

export function initPhotosDelete(payload: {
    page_id: number;
    id: number
}): ActionWithPayloadBase<{
    page_id: number;
    id: number
}> {
    return {
        type: constants.INIT_PHOTO_DELETE,
        payload
    };
}

export function submitPhotosDelete(dataPath: string, payload: string = null): ActionWithDataPathBase<string> {
    return {
        type: constants.DELETE_PHOTO_SUBMIT,
        dataPath,
        payload
    };
}

export function initQuotesAdd(dataPath: string, payload: number): ActionWithDataPathBase<number> {
    return {
        type: constants.INIT_QUOTE_ADD,
        dataPath,
        payload
    };
}

export function submitQuotesAdd(dataPath: string, payload: ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>): ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>> {
    return {
        type: constants.ADD_QUOTE_SUBMIT,
        dataPath,
        payload
    };
}

export function initQuotesEdit(dataPath: string, payload: {
    page_id: number;
    id: number
}): ActionWithDataPathBase<{
    page_id: number;
    id: number
}> {
    return {
        type: constants.INIT_QUOTE_EDIT,
        dataPath,
        payload
    };
}

export function submitQuotesEdit(dataPath: string, payload: ActionWithCallback<{
    id: number;
    page_id: number;
    tableDataPath: string;
}>): ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    page_id: number;
    tableDataPath: string;
}>> {
    return {
        type: constants.EDIT_QUOTE_SUBMIT,
        dataPath,
        payload
    };
}

export function initQuotesDelete(payload: {
    page_id: number;
    id: number
}): ActionWithPayloadBase<{
    page_id: number;
    id: number
}> {
    return {
        type: constants.INIT_QUOTE_DELETE,
        payload
    };
}

export function submitQuotesDelete(dataPath: string, payload: string = null): ActionWithDataPathBase<string> {
    return {
        type: constants.DELETE_QUOTE_SUBMIT,
        dataPath,
        payload
    };
}

export type PagesActions = ReturnType<
    typeof changeValue |
    typeof getRepression |
    typeof saveRepression |
    typeof initPhotoAdd |
    typeof submitPhotoAdd |
    typeof initPhotosEdit |
    typeof submitPhotosEdit |
    typeof initPhotosDelete |
    typeof submitPhotosDelete |
    typeof initQuotesAdd |
    typeof submitQuotesAdd |
    typeof initQuotesEdit |
    typeof submitQuotesEdit |
    typeof initQuotesDelete |
    typeof submitQuotesDelete
>;