import produce from "immer";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../../actions/actionWithDataPathBase";
import { DictionariesPrisonsActions } from "../../../actions/dictionaries/prisons";
import * as constants from "../../../constants/dictionaries/prisons";
import VictimsDisplayMode from "../../../enums/dictionaries/victimsDisplayMode";
import DictionaryPrisonModel from "../../../models/dictionaries/DictionaryPrisonModel";

export interface Prisons {
    mode: VictimsDisplayMode;
    isOpen: boolean;
    items: Array<DictionaryPrisonModel>;
    isLoading: boolean;
};

export const prisonsDefaultState: Prisons = {
    mode: VictimsDisplayMode.List,
    isOpen: false,
    items: [],
    isLoading: false
}

const prisonsReducer = (state: Prisons = prisonsDefaultState, action: DictionariesPrisonsActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.GET_ITEMS:
        case constants.SEARCH:
            return produce(state, draft => {
                draft.isLoading = true;
                draft.mode = VictimsDisplayMode.List;
            });
        default:
            return state;
    }
}

export default prisonsReducer;