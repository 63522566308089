import PlacesForCreationModel from "./PlacesForCreationModel";

export default class PlacesForUpdateModel extends PlacesForCreationModel {
    id: number;
    coordinates: {
        longitude: number;
        latitude: number;
    }
    is_published: boolean;

    constructor(obj?: any) {
        super(obj);
        this.id = obj?.id;
        this.coordinates = {
            longitude: obj?.longitude ?? 24.6988637,
            latitude: obj?.latitude ?? 45.9872227
        }
        this.is_published = obj?.is_published;
    }
}