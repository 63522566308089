import { all } from "redux-saga/effects";
import people from "./people";
import places from "./places";
import instruments from "./instruments";
import pages from "./pages";

export default function* rootSaga() {
    yield all([
        people(),
        places(),
        instruments(),
        pages()
    ]);
}