export default class PagePhotoForCreationModel {
    page_id: number;
    picture: File;
    order_no: number;
    url: string;

    constructor(page_id: number) {
        this.page_id = page_id;
    }
}

export class PagePhotoForUpdateModel extends PagePhotoForCreationModel {
    id: number;

    constructor(obj?: PagePhotoForUpdateModel) {
        super(obj?.page_id);
        this.order_no = obj?.order_no;
        this.url = obj?.url;
        this.id = obj?.id;
    }
}