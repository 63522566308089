import produce from "immer";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../../actions/actionWithDataPathBase";
import { RepressionStoryActions } from "../../../actions/repression/story";
import * as constants from "../../../constants/repression/story";

import StoryModel from "../../../models/repression/People/story";

export interface Story {
    isLoading: boolean;
    data: StoryModel;
}

export const getDefaultStory: Story = {
    isLoading: false,
    data: null
}

const storyReducer = (state: Story = getDefaultStory, action: RepressionStoryActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.GET_STORY:
            return produce(state, draft => {
                draft.isLoading = true;
            });
        default:
            return state;
    }
}

export default storyReducer;