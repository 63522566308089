import { ActionWithDataPathBase } from "../../../actionWithDataPathBase";
import { ActionWithCallback } from "../../../actionWithCallback";
import * as constants from "../../../../constants/administration/security/users";
import { ActionWithPayloadBase } from "../../../actionWithPayloadBase";

export function changeValue(dataPath: string, payload: any): ActionWithDataPathBase<any> {
    return {
        type: constants.CHANGE_VALUE,
        dataPath,
        payload
    };
}

export function initAdd(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.INIT_ADD,
        dataPath,
        payload
    };
}

export function submitAdd(dataPath: string, payload: ActionWithCallback<string>): ActionWithDataPathBase<ActionWithCallback<string>> {
    return {
        type: constants.ADD_SUBMIT,
        dataPath,
        payload
    };
}

export function initEdit(dataPath: string, payload: number): ActionWithDataPathBase<number> {
    return {
        type: constants.INIT_EDIT,
        dataPath,
        payload
    };
}



export function submitEdit(dataPath: string, payload: ActionWithCallback<{
    formPath: string;
    id: number;
}>): ActionWithDataPathBase<ActionWithCallback<{
    formPath: string;
    id: number;
}>> {
    return {
        type: constants.EDIT_SUBMIT,
        dataPath,
        payload
    };
}

export function initChangePassword(dataPath: string, payload: number): ActionWithDataPathBase<number> {
    return {
        type: constants.INIT_CHANGE_PASSWORD,
        dataPath,
        payload
    };
}

export function submitChangePassword(dataPath: string, payload: ActionWithCallback<{
    formPath: string;
    id: number;
}>): ActionWithDataPathBase<ActionWithCallback<{
    formPath: string;
    id: number;
}>> {
    return {
        type: constants.CHANGE_PASSWORD_SUBMIT,
        dataPath,
        payload
    };
}

export function initDelete(payload: number): ActionWithPayloadBase<number> {
    return {
        type: constants.INIT_DELETE,
        payload
    };
}

export function submitDelete(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.DELETE_SUBMIT,
        dataPath,
        payload
    };
}

export type UsersActions = ReturnType<
    typeof changeValue |
    typeof initAdd |
    typeof submitAdd |
    typeof initEdit |
    typeof submitEdit |
    typeof initChangePassword |
    typeof submitChangePassword |
    typeof initDelete |
    typeof submitDelete
>;