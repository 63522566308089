import * as React from "react";
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import configureStore, { history } from './store';
import Loading from "./containers/LazyCircularProgress";

import { ToastContainer } from "react-toastify";

import { defaultAppState } from "./store/appState";
import 'react-toastify/dist/ReactToastify.css';

const App = React.lazy(() => import("./containers/App"));

export const store = configureStore(defaultAppState);

const MainApp = () => {
    return (
        <Provider store={store}>
            <ToastContainer />
            <React.Suspense fallback={<Loading />}>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route path="/" component={App} />
                    </Switch>
                </ConnectedRouter>
            </React.Suspense>
        </Provider>
    );
}

export default MainApp;