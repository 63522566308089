import * as constants from "../../constants/auth/login";
import { ActionWithPayloadBase } from "../actionWithPayloadBase";
import { ActionWithDataPathBase } from "../actionWithDataPathBase";
import { ActionWithCallback } from "../actionWithCallback";

export function changeValue(dataPath: string, payload: any): ActionWithDataPathBase<any> {
    return {
        type: constants.CHANGE_VALUE,
        dataPath,
        payload
    };
}

export function initForm(payload: string): ActionWithPayloadBase<string> {
    return {
        type: constants.INIT_FORM,
        payload
    };
}

export function submit(payload: ActionWithCallback<string>): ActionWithPayloadBase<ActionWithCallback<string>> {
    return {
        type: constants.SUBMIT,
        payload
    };
}

export type LoginActions = ReturnType<
    typeof changeValue |
    typeof initForm |
    typeof submit
>;