export default interface IVideoGallery {
    isLoading: boolean;
    values: Array<any>;
    crtPage: number;
    total: number;
    rowsPerPage: number;
    error: string | null;
    url: string | null;
}

export const getDefaultGallery: IVideoGallery = {
    isLoading: false,
    values: [],
    crtPage: 1,
    total: 0,
    rowsPerPage: 9,
    error: null,
    url: null
};