enum ColorThemes {
    INDIGO = "indigo",
    CYAN = "cyan",
    AMBER = "amber",
    DEEP_ORANGE = "deep-orange",
    PINK = "pink",
    BLUE = "blue",
    DEEP_PURPLE = "deep-purple",
    GREEN = "green",

    DARK_INDIGO = "dark-indigo",
    DARK_CYAN = "dark-cyan",
    DARK_AMBER = "dark-amber",
    DARK_DEEP_ORANGE = "dark-deep-orange",
    DARK_PINK = "dark-pink",
    DARK_BLUE = "dark-blue",
    DARK_DEEP_PURPLE = "dark-deep-purple",
    DARK_GREEN = "dark-green",
    DARK_THEME_COLOR = "dark-theme",
};

export default ColorThemes;