import { combineReducers } from "redux";
import coreReducer, { Core, getDefaultCore } from "./core";
import securityReducer, { getDefaultSecurity, Security } from "./security";

export interface Administration {
    security: Security;
    core: Core;
}

export const getDefaultAdministration: Administration = {
    security: getDefaultSecurity,
    core: getDefaultCore
}

export default combineReducers({
    security: securityReducer,
    core: coreReducer
});