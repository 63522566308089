export class PeoplePhotoForCreateModel {
    people_id: number;
    picture: File;
    order_no: number
    description: string;
    subtitle: string;
    show_in_header: boolean;
    url: string;

    constructor(people_id: number) {
        this.people_id = people_id;
    }
}

export class PeoplePhotoForUpdateModel extends PeoplePhotoForCreateModel {
    id: number;

    constructor(obj?: PeoplePhotoForUpdateModel) {
        super(obj?.people_id);

        this.id = obj?.id;
        this.order_no = obj?.order_no;
        this.description = obj?.description;
        this.subtitle = obj?.subtitle;
        this.show_in_header = obj?.show_in_header;
        this.url = obj?.url;
    }
}