import { ActionWithDataPathBase } from "../actionWithDataPathBase";
import * as constants from "../../constants/contact";
import { ActionWithCallback } from "../actionWithCallback";

export function changeValue(dataPath: string, payload: any): ActionWithDataPathBase<any> {
    return {
        type: constants.CHANGE_VALUE,
        dataPath,
        payload
    };
}

export function initForm(dataPath: string, payload: null): ActionWithDataPathBase<null> {
    return {
        type: constants.INIT_FORM,
        dataPath,
        payload
    };
}

export function submitForm(dataPath: string, payload: ActionWithCallback<null>): ActionWithDataPathBase<ActionWithCallback<number>> {
    return {
        type: constants.SUBMIT_FORM,
        dataPath,
        payload
    };
}

export type ContactActions = ReturnType<typeof changeValue |
    typeof initForm |
    typeof submitForm>;