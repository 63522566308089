const MODULE = "ADMINISTRATION_CORE_PLACES_";

export const CHANGE_VALUE = `${MODULE}CHANGE_VALUE`;
export type CHANGE_VALUE = typeof CHANGE_VALUE;

export const INIT_ADD = `${MODULE}INIT_ADD`;
export type INIT_ADD = typeof INIT_ADD;

export const ADD_SUBMIT = `${MODULE}ADD_SUBMIT`;
export type ADD_SUBMIT = typeof ADD_SUBMIT;

export const INIT_EDIT = `${MODULE}INIT_EDIT`;
export type INIT_EDIT = typeof INIT_EDIT;

export const EDIT_SUBMIT = `${MODULE}EDIT_SUBMIT`;
export type EDIT_SUBMIT = typeof EDIT_SUBMIT;

export const INIT_DELETE = `${MODULE}INIT_DELETE`;
export type INIT_DELETE = typeof INIT_DELETE;

export const DELETE_SUBMIT = `${MODULE}DELETE_SUBMIT`;
export type DELETE_SUBMIT = typeof DELETE_SUBMIT;

export const GET_HOMEPAGE = `${MODULE}GET_HOMEPAGE`;
export type GET_HOMEPAGE = typeof GET_HOMEPAGE;

export const SAVE_HOMEPAGE = `${MODULE}SAVE_HOMEPAGE`;
export type SAVE_HOMEPAGE = typeof SAVE_HOMEPAGE;

export const INIT_WORKERS_ADD = `${MODULE}INIT_WORKERS_ADD`;
export type INIT_WORKERS_ADD = typeof INIT_WORKERS_ADD;

export const ADD_WORKERS_SUBMIT = `${MODULE}ADD_WORKERS_SUBMIT`;
export type ADD_WORKERS_SUBMIT = typeof ADD_WORKERS_SUBMIT;

export const INIT_WORKERS_EDIT = `${MODULE}INIT_WORKERS_EDIT`;
export type INIT_WORKERS_EDIT = typeof INIT_WORKERS_EDIT;

export const EDIT_WORKERS_SUBMIT = `${MODULE}EDIT_WORKERS_SUBMIT`;
export type EDIT_WORKERS_SUBMIT = typeof EDIT_WORKERS_SUBMIT;

export const INIT_WORKERS_DELETE = `${MODULE}INIT_WORKERS_DELETE`;
export type INIT_WORKERS_DELETE = typeof INIT_WORKERS_DELETE;

export const DELETE_WORKERS_SUBMIT = `${MODULE}DELETE_WORKERS_SUBMIT`;
export type DELETE_WORKERS_SUBMIT = typeof DELETE_WORKERS_SUBMIT;

export const INIT_MANAGEMENT_ADD = `${MODULE}INIT_MANAGEMENT_ADD`;
export type INIT_MANAGEMENT_ADD = typeof INIT_MANAGEMENT_ADD;

export const ADD_MANAGEMENT_SUBMIT = `${MODULE}ADD_MANAGEMENT_SUBMIT`;
export type ADD_MANAGEMENT_SUBMIT = typeof ADD_MANAGEMENT_SUBMIT;

export const INIT_MANAGEMENT_EDIT = `${MODULE}INIT_MANAGEMENT_EDIT`;
export type INIT_MANAGEMENT_EDIT = typeof INIT_MANAGEMENT_EDIT;

export const EDIT_MANAGEMENT_SUBMIT = `${MODULE}EDIT_MANAGEMENT_SUBMIT`;
export type EDIT_MANAGEMENT_SUBMIT = typeof EDIT_MANAGEMENT_SUBMIT;

export const INIT_MANAGEMENT_DELETE = `${MODULE}INIT_MANAGEMENT_DELETE`;
export type INIT_MANAGEMENT_DELETE = typeof INIT_MANAGEMENT_DELETE;

export const DELETE_MANAGEMENT_SUBMIT = `${MODULE}DELETE_MANAGEMENT_SUBMIT`;
export type DELETE_MANAGEMENT_SUBMIT = typeof DELETE_MANAGEMENT_SUBMIT;

export const UPLOAD_CATEGORY_PHOTO = `${MODULE}UPLOAD_CATEGORY_PHOTO`;
export type UPLOAD_CATEGORY_PHOTO = typeof UPLOAD_CATEGORY_PHOTO;