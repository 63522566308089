import produce from "immer";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../../actions/actionWithDataPathBase";
import { RepressionStoriesActions } from "../../../actions/repression/stories"
import * as constants from "../../../constants/repression/stories";
import StoryItem from "../../../models/repression/storyItem";

export interface Stories {
    isLoading: boolean;
    items: Array<StoryItem>;
}

export const getDefaultStories: Stories = {
    isLoading: false,
    items: []
}

const storiesReducer = (state: Stories = getDefaultStories, action: RepressionStoriesActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.GET_STORIES:
            return produce(state, draft => {
                draft.isLoading = true;
            });
        default:
            return state;
    }
}

export default storiesReducer;