import * as constants from "../../constants/documentViewer";
import { ActionWithDataPathBase } from "../actionWithDataPathBase";
import { ActionWithPayloadBase } from "../actionWithPayloadBase";

export function initNewViewer(dataPath: string, payload: string): ActionWithDataPathBase<string> {
    return {
        type: constants.INIT_NEW_VIEWER,
        dataPath,
        payload
    };
}

export function removeViewer(payload: string): ActionWithPayloadBase<string> {
    return {
        type: constants.REMOVE_VIEWER,
        payload
    }
}

export function getData(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.GET_REQUEST,
        dataPath,
        payload
    };
}

export type DocumentViewerActions = ReturnType<
    typeof initNewViewer |
    typeof removeViewer |
    typeof getData
>;