import { push } from "connected-react-router";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { ActionWithPayloadBase } from "../../actions/actionWithPayloadBase";
import * as constants from "../../constants/search";
import ajax from "../../util/api";

function* getResults(action: ActionWithPayloadBase<string>) {
    try {
        const { data } = yield call(ajax.get, `/search?filter[search]=${action.payload}&sort=title&append=avatar_url`);
        console.log(data);
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "items",
            payload: data.data
        });
    } catch (error) {
        if (error.response) {
            switch (error.status) {
                case 404:
                    yield put(push("/404"));
                    break;
                default:
                    yield put(push("/500"));
                    break;
            }
        } else {
            yield put(push("/500"));
        }
    } finally {
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "isLoading",
            payload: false
        });
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.GET_RESULTS, getResults)
    ]);
}