import * as constants from "../../constants/auth";
import User from "../../models/auth/IUser";
import { ActionBase } from "../actionBase";
import { ActionWithPayloadBase } from "../actionWithPayloadBase";

export function setInitUrl(payload: string): ActionWithPayloadBase<string> {
    return {
        type: constants.INIT_URL,
        payload
    };
}

export function setUser(payload: User): ActionWithPayloadBase<User> {
    return {
        type: constants.SET_USER,
        payload
    }
}

export function signOut(): ActionBase {
    return {
        type: constants.SIGNOUT_USER
    };
}

export function signOutSuccess(): ActionBase {
    return {
        type: constants.SIGNOUT_USER_SUCCESS
    };
}

export type AuthActions = ReturnType<
    typeof setInitUrl |
    typeof setUser |
    typeof signOut |
    typeof signOutSuccess
>;