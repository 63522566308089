import * as constants from "../../constants/forms";
import { ActionWithDataPathBase } from "../actionWithDataPathBase";
import { ActionWithPayloadBase } from "../actionWithPayloadBase";

export function changeValue(dataPath: string, payload: any): ActionWithDataPathBase<any> {
    return {
        type: constants.CHANGE_VALUE,
        dataPath,
        payload
    };
}

export function removeForm(payload: string): ActionWithPayloadBase<string> {
    return {
        type: constants.REMOVE_FORM,
        payload
    }
}

export type FormsActions = ReturnType<typeof changeValue | typeof removeForm>;