import { all, takeEvery, call, select, put } from "redux-saga/effects";
import get from "lodash/get";
import ajax from "../../../../util/api";
import { handleFormErrors, handleRequestErrors } from "../../../../util/errorHandling";

import UserForCreationModel, { getDefaultUserForCreationModel } from "../../../../models/administration/security/users/UserForCreationModel";
import UserForUpdateModel from "../../../../models/administration/security/users/UserForUpdateModel";

import { ActionWithDataPathBase } from "../../../../actions/actionWithDataPathBase";
import { ActionWithCallback } from "../../../../actions/actionWithCallback";
import { AppState } from "../../../../store/appState";

import * as constants from "../../../../constants/administration/security/users";
import * as actions from "../../../../actions/administration/security/users";
import * as tableActions from "../../../../actions/tables";

import { putNewForm } from "../../../forms";
import ChangePasswordModel, { getDefaultChangePasswordModel } from "../../../../models/administration/security/users/ChangePasswordModel";

function* initAdd(action: ActionWithDataPathBase<null>) {
    yield call(putNewForm, action.dataPath, getDefaultUserForCreationModel);
}

function* create(action: ActionWithDataPathBase<ActionWithCallback<string>>) {
    try {
        const formData: UserForCreationModel = yield select((state: AppState) => get(state.forms, action.payload.data));
        yield call(ajax.post, "/users", formData);
        yield call(closeDialogAndRefreshGrid, "add.isOpen", action.dataPath);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("add.isLoading", false));
    }
}

function* initEdit(action: ActionWithDataPathBase<number>) {
    try {
        const { data } = yield call(ajax.get, `/users/${action.payload}`);
        yield call(putNewForm, action.dataPath, new UserForUpdateModel(data));
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "edit", message);
    }
}

function* update(action: ActionWithDataPathBase<ActionWithCallback<{
    formPath: string;
    id: number;
}>>) {
    try {
        const formData: UserForUpdateModel = yield select((state: AppState) => get(state.forms, action.payload.data.formPath));
        yield call(ajax.put, `/users/${action.payload.data.id}`, formData);
        yield call(closeDialogAndRefreshGrid, "edit.isOpen", action.dataPath);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("edit.isLoading", false));
    }
}

function* initChangePassword(action: ActionWithDataPathBase<number>) {
    yield call(putNewForm, action.dataPath, getDefaultChangePasswordModel);
}

function* changePassword(action: ActionWithDataPathBase<ActionWithCallback<{
    formPath: string;
    id: number;
}>>) {
    try {
        const formData: ChangePasswordModel = yield select((state: AppState) => get(state.forms, action.payload.data.formPath));
        yield call(ajax.put, `/users/change-password/${action.payload.data.id}`, formData);
        yield call(closeDialogAndRefreshGrid, "change_password.isOpen", action.dataPath);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("change_password.isLoading", false));
    }
}

function* deleteUser(action: ActionWithDataPathBase<null>) {
    try {
        const { id } = yield select((state: AppState) => state.administration.security.users.delete);
        yield call(ajax.delete, `/users/${id}`);
        yield call(closeDialogAndRefreshGrid, "delete.isOpen", action.dataPath);
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "delete", message);
    }
    finally {
        yield put(actions.changeValue("delete.isLoading", false));
    }
}

function* closeDialogAndRefreshGrid(dataPath: string, tableDataPath: string) {
    yield all([
        put(actions.changeValue(dataPath, false)),
        put(tableActions.getData(tableDataPath, {
            url: "/users"
        }))
    ]);
}

function* setDialogErrors(basePath: string, message: string) {
    yield all([
        put(actions.changeValue(`${basePath}.errors`, message)),
        put(actions.changeValue(`${basePath}.isOpen`, false))
    ]);
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.INIT_ADD, initAdd),
        takeEvery(constants.ADD_SUBMIT, create),
        takeEvery(constants.INIT_EDIT, initEdit),
        takeEvery(constants.EDIT_SUBMIT, update),
        takeEvery(constants.INIT_CHANGE_PASSWORD, initChangePassword),
        takeEvery(constants.CHANGE_PASSWORD_SUBMIT, changePassword),
        takeEvery(constants.DELETE_SUBMIT, deleteUser)
    ]);
}