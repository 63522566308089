import InstrumentPositionForCreationModel from "./InstrumentPositionForCreationModel";

export default class InstrumentPositionForUpdateModel extends InstrumentPositionForCreationModel {
    id: number;

    constructor(obj?: InstrumentPositionForUpdateModel) {
        super(obj.instrument_id);
        this.id = obj?.id;
        this.name = obj?.name;
        this.location = obj?.location;
        this.period = obj?.period;
    }
}