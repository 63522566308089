const MODULE = "ADMINISTRATION_CORE_INSTRUMENTS_";

export const CHANGE_VALUE = `${MODULE}CHANGE_VALUE`;
export type CHANGE_VALUE = typeof CHANGE_VALUE;

export const INIT_ADD = `${MODULE}INIT_ADD`;
export type INIT_ADD = typeof INIT_ADD;

export const ADD_SUBMIT = `${MODULE}ADD_SUBMIT`;
export type ADD_SUBMIT = typeof ADD_SUBMIT;

export const INIT_EDIT = `${MODULE}INIT_EDIT`;
export type INIT_EDIT = typeof INIT_EDIT;

export const EDIT_SUBMIT = `${MODULE}EDIT_SUBMIT`;
export type EDIT_SUBMIT = typeof EDIT_SUBMIT;

export const INIT_DELETE = `${MODULE}INIT_DELETE`;
export type INIT_DELETE = typeof INIT_DELETE;

export const DELETE_SUBMIT = `${MODULE}DELETE_SUBMIT`;
export type DELETE_SUBMIT = typeof DELETE_SUBMIT;

export const INIT_POSITIONS_ADD = `${MODULE}INIT_POSITIONS_ADD`;
export type INIT_POSITIONS_ADD = typeof INIT_POSITIONS_ADD;

export const ADD_POSITIONS_SUBMIT = `${MODULE}ADD_POSITIONS_SUBMIT`;
export type ADD_POSITIONS_SUBMIT = typeof ADD_POSITIONS_SUBMIT;

export const INIT_POSITIONS_EDIT = `${MODULE}INIT_POSITIONS_EDIT`;
export type INIT_POSITIONS_EDIT = typeof INIT_POSITIONS_EDIT;

export const EDIT_POSITIONS_SUBMIT = `${MODULE}EDIT_POSITIONS_SUBMIT`;
export type EDIT_POSITIONS_SUBMIT = typeof EDIT_POSITIONS_SUBMIT;

export const INIT_POSITIONS_DELETE = `${MODULE}INIT_POSITIONS_DELETE`;
export type INIT_POSITIONS_DELETE = typeof INIT_POSITIONS_DELETE;

export const DELETE_POSITIONS_SUBMIT = `${MODULE}DELETE_POSITIONS_SUBMIT`;
export type DELETE_POSITIONS_SUBMIT = typeof DELETE_POSITIONS_SUBMIT;

export const GET_HOMEPAGE = `${MODULE}GET_HOMEPAGE`;
export type GET_HOMEPAGE = typeof GET_HOMEPAGE;

export const SAVE_HOMEPAGE = `${MODULE}SAVE_HOMEPAGE`;
export type SAVE_HOMEPAGE = typeof SAVE_HOMEPAGE;