import produce from "immer";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../../actions/actionWithDataPathBase";
import { RepressionPlacesActions } from "../../../actions/repression/places"
import * as constants from "../../../constants/repression/places";
import PlaceHomepageFrontModel from "../../../models/repression/Places/PlaceHomepageFrontModel";
import PlaceModel from "../../../models/repression/Places/PlaceModel";

export interface Places {
    isLoading: boolean;
    content: PlaceHomepageFrontModel;
    places: Array<PlaceModel>;
}

export const getDefaultPlaces: Places = {
    isLoading: false,
    content: null,
    places: []
}

const placesReducer = (state: Places = getDefaultPlaces, action: RepressionPlacesActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.GET_PLACES_HOMEPAGE:
        case constants.GET_PLACES:
            return produce(state, draft => {
                draft.isLoading = true;
            });
        default:
            return state;
    }
}

export default placesReducer;