import PeopleDocument from "./PeopleDocument";
import PeoplePhoto from "./PeoplePhoto";
import PeopleVideo from "./PeopleVideo";

export default class Story {
    last_name: string;
    first_name: string;
    imprisoned_at: number;
    story: string;

    photos: Array<PeoplePhoto>;
    videos: Array<PeopleVideo>;
    document: PeopleDocument;

    constructor(obj?: Story) {
        this.last_name = obj?.last_name;
        this.first_name = obj?.first_name;
        this.imprisoned_at = obj?.imprisoned_at;
        this.story = obj?.story;

        this.photos = obj?.photos ? obj.photos.map((el: any) => new PeoplePhoto(el)) : [];
        this.videos = obj?.videos ? obj.videos.map(el => new PeopleVideo(el)) : [];
        this.document = obj?.document ? new PeopleDocument(obj.document) : null;
    }
}