import produce from "immer";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../../../actions/actionWithDataPathBase";
import { ActionWithPayloadBase } from "../../../../actions/actionWithPayloadBase";
import { PeopleActions } from "../../../../actions/administration/core/people";
import * as constants from "../../../../constants/administration/core/people";

export interface People {
    homepage: {
        gettingData: boolean;
        isLoading: boolean;
        errors: string;
    },
    add: {
        isOpen: boolean;
        isLoading: boolean;
    },
    edit: {
        gettingData: boolean;
        isLoading: boolean;
        errors: string | null;
    },
    delete: {
        isOpen: boolean;
        isLoading: boolean;
        errors: string | null;
        id: number | null;
    },
    photos: {
        add: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
        },
        edit: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
        },
        delete: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
            id: number | null;
            people_id: number | null;
        }
    },
    videos: {
        add: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
        },
        edit: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
        },
        delete: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
            id: number | null;
            people_id: number | null;
        }
    },
    document: {
        isLoading: boolean;
        errors: string;
    }
}

export const getDefaultPeople: People = {
    homepage: {
        gettingData: false,
        isLoading: false,
        errors: null
    },
    add: {
        isOpen: false,
        isLoading: false
    },
    edit: {
        gettingData: false,
        isLoading: false,
        errors: null
    },
    delete: {
        isOpen: false,
        isLoading: false,
        errors: null,
        id: null
    },
    photos: {
        add: {
            isOpen: false,
            isLoading: false,
            errors: null
        },
        edit: {
            isOpen: false,
            isLoading: false,
            errors: null
        },
        delete: {
            isOpen: false,
            isLoading: false,
            errors: null,
            id: null,
            people_id: null
        }
    },
    videos: {
        add: {
            isOpen: false,
            isLoading: false,
            errors: null
        },
        edit: {
            isOpen: false,
            isLoading: false,
            errors: null
        },
        delete: {
            isOpen: false,
            isLoading: false,
            errors: null,
            id: null,
            people_id: null
        }
    },
    document: {
        isLoading: false,
        errors: null
    }
}

const peopleReducer = (state: People = getDefaultPeople, action: PeopleActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.INIT_ADD:
            return produce(state, draft => {
                draft.add.isOpen = true;
            });
        case constants.ADD_SUBMIT:
            return produce(state, draft => {
                draft.add.isLoading = true;
            });
        case constants.EDIT_SUBMIT:
            return produce(state, draft => {
                draft.edit.isLoading = true;
            });
        case constants.INIT_DELETE:
            return produce(state, draft => {
                draft.delete.isOpen = true;
                draft.delete.id = (action as ActionWithDataPathBase<number>).payload;
            });
        case constants.DELETE_SUBMIT:
            return produce(state, draft => {
                draft.delete.isLoading = true;
            });
        case constants.INIT_PHOTO_ADD:
            return produce(state, draft => {
                draft.photos.add.isOpen = true;
            });
        case constants.ADD_PHOTO_SUBMIT:
            return produce(state, draft => {
                draft.photos.add.isLoading = true;
            });
        case constants.INIT_PHOTO_EDIT:
            return produce(state, draft => {
                draft.photos.edit.isOpen = true;
            });
        case constants.EDIT_PHOTO_SUBMIT:
            return produce(state, draft => {
                draft.photos.edit.isLoading = true;
            });
        case constants.INIT_PHOTO_DELETE:
            return produce(state, draft => {
                draft.photos.delete.isOpen = true;
                const { payload } = (action as ActionWithPayloadBase<{
                    people_id: number;
                    id: number
                }>);
                draft.photos.delete.id = payload.id;
                draft.photos.delete.people_id = payload.people_id;
            });
        case constants.DELETE_PHOTO_SUBMIT:
            return produce(state, draft => {
                draft.photos.delete.isLoading = true;
            });
        case constants.INIT_VIDEO_ADD:
            return produce(state, draft => {
                draft.videos.add.isOpen = true;
            });
        case constants.ADD_VIDEO_SUBMIT:
            return produce(state, draft => {
                draft.videos.add.isLoading = true;
            });
        case constants.INIT_VIDEO_EDIT:
            return produce(state, draft => {
                draft.videos.edit.isOpen = true;
            });
        case constants.EDIT_VIDEO_SUBMIT:
            return produce(state, draft => {
                draft.videos.edit.isLoading = true;
            });
        case constants.INIT_VIDEO_DELETE:
            return produce(state, draft => {
                draft.videos.delete.isOpen = true;
                const { payload } = (action as ActionWithPayloadBase<{
                    people_id: number;
                    id: number
                }>);
                draft.videos.delete.id = payload.id;
                draft.videos.delete.people_id = payload.people_id;
            });
        case constants.DELETE_VIDEO_SUBMIT:
            return produce(state, draft => {
                draft.videos.delete.isLoading = true;
            });
        case constants.UPLOAD_DOCUMENT:
        case constants.DELETE_DOCUMENT:
            return produce(state, draft => {
                draft.document.isLoading = true;
            });
        case constants.GET_HOMEPAGE:
            return produce(state, draft => {
                draft.homepage.gettingData = true;
            });
        case constants.SAVE_HOMEPAGE:
            return produce(state, draft => {
                draft.homepage.isLoading = true;
            });
        default:
            return state;
    }
}

export default peopleReducer;