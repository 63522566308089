export default class StoryItem {
    id: number;
    first_name: string;
    last_name: string;
    imprisoned_at: number;
    story_url: string;

    constructor(obj?: StoryItem) {
        this.id = obj?.id;
        this.first_name = obj?.first_name;
        this.last_name = obj?.last_name;
        this.imprisoned_at = obj?.imprisoned_at;
        this.story_url = obj?.story_url;
    }
}