import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";

const LazyCircularProgress = () => (
    <div className="loader-view" style={{ height: 'calc(100vh - 200px)' }}>
        <CircularProgress />
    </div>
);

export default LazyCircularProgress;