import produce from "immer";
import set from "lodash/set";
import { LoginActions } from "../../../actions/auth/login";
import * as constants from "../../../constants/auth/login";
import { ActionWithDataPathBase } from "../../../actions/actionWithDataPathBase";

export interface Login {
    isLoading: boolean;
}

export const getDefaultLogin = {
    isLoading: false
}

const loginReducer = (state: Login = getDefaultLogin, action: LoginActions): Login => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.SUBMIT:
            return produce(state, draft => {
                draft.isLoading = true;
            });
        default:
            return state;
    }
}

export default loginReducer;