import { push } from "connected-react-router";
import get from "lodash/get";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { ActionWithDataPathBase } from "../../actions/actionWithDataPathBase";

import * as constants from "../../constants/documentViewer";
import * as actions from "../../actions/documentViewer";
import { AppState } from "../../store/appState";

import ajax from "../../util/api";
import { getDefaultGallery } from "../../models/common/IDocumentViewer";
import set from "lodash/set";

export function* putNewGallery(action: ActionWithDataPathBase<string>) {
    const payload = getDefaultGallery;
    set(payload, "url", action.payload);
    yield all([
        put({
            type: constants.PUT_NEW_VIEWER,
            dataPath: action.dataPath,
            payload
        }),
        put(actions.getData(action.dataPath))
    ]);
}

function* getData(action: ActionWithDataPathBase<string>) {
    try {
        const { url } = yield select((state: AppState) => get(state, `documentViewer.${action.dataPath}`));
        const urlToCall = url;

        const { data } = yield call(ajax.get, urlToCall);
        yield put({
            type: constants.GET_SUCCESS,
            dataPath: action.dataPath,
            payload: data
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    yield put(push("/administration/404"));
                    break;
                default:
                    yield put({
                        type: constants.GET_FAILURE,
                        dataPath: action.dataPath,
                        payload: (error.response.data && error.response.data.message) ?? "Failed to load data"
                    });
                    break;
            }
        } else {
            yield put({
                type: constants.GET_FAILURE,
                dataPath: action.dataPath,
                payload: "Failed to load data"
            });
        }
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.INIT_NEW_VIEWER, putNewGallery),
        takeEvery(constants.GET_REQUEST, getData),
    ]);
}