export default class DictionaryVictimModel {
    id: number;
    last_name: string;
    first_name: string;
    avatar_url: string;
    date_of_birth: string;
    imprisonments: string;
    places: string;
    imprisoned_at: number;

    constructor(obj?: DictionaryVictimModel) {
        this.id = obj?.id;
        this.last_name = obj?.last_name;
        this.first_name = obj?.first_name;
        this.avatar_url = obj?.avatar_url;
        this.date_of_birth = obj?.date_of_birth;
        this.imprisonments = obj?.imprisonments;
        this.places = obj?.places;
        this.imprisoned_at = obj?.imprisoned_at;
    }
}