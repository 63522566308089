import { DrawerTypes } from "../../enums/settings/drawerTypes";
import { NavigationTypes } from "../../enums/settings/navigationTypes";
import { HorizontalNavPositions } from "../../enums/settings/horizontalNavPositions";
import ColorThemes from "../../enums/settings/colorThemes";
import getDefaultLocale, { Locale } from "./locale";
import { SettingsActions } from "../../actions/settings";
import * as constants from "../../constants/settings";
import produce from "immer";
import { ActionWithPayloadBase } from "../../actions/actionWithPayloadBase";

export interface Settings {
    navCollapsed: boolean;
    drawerType: DrawerTypes;
    themeColor: ColorThemes;
    darkTheme: boolean;
    width: number;
    navigationStyle: NavigationTypes;
    horizontalNavPosition: HorizontalNavPositions;
    locale: Locale;
};

export const getDefaultSettings: Settings = {
    navCollapsed: false,
    drawerType: DrawerTypes.FIXED_DRAWER,
    themeColor: ColorThemes.DARK_PINK,
    darkTheme: false,
    width: window.innerWidth,
    navigationStyle: NavigationTypes.VERTICAL_NAVIGATION,
    horizontalNavPosition: HorizontalNavPositions.INSIDE_THE_HEADER,
    locale: getDefaultLocale
};

sessionStorage.setItem("locale", getDefaultSettings.locale.locale);

const settingsReducer = (state: Settings = getDefaultSettings, action: SettingsActions): Settings => {
    switch (action.type) {
        case "@@router/LOCATION_CHANGE":
            return produce(state, draft => {
                draft.navCollapsed = false;
            });
        case constants.TOGGLE_COLLAPSED_NAV:
            return produce(state, draft => {
                draft.navCollapsed = (action as ActionWithPayloadBase<boolean>).payload;
            });
        case constants.WINDOW_WIDTH:
            return produce(state, draft => {
                draft.width = (action as ActionWithPayloadBase<number>).payload;
            });
        case constants.SWITCH_LANGUAGE:
            const { payload } = (action as ActionWithPayloadBase<Locale>);
            sessionStorage.setItem("locale", payload.locale);
            window.location.reload();
            break;
        case constants.CHANGE_NAVIGATION_STYLE:
            return produce(state, draft => {
                draft.navigationStyle = (action as ActionWithPayloadBase<NavigationTypes>).payload;
            });
        case constants.HORIZONTAL_MENU_POSITION:
            return produce(state, draft => {
                draft.horizontalNavPosition = (action as ActionWithPayloadBase<HorizontalNavPositions>).payload;
            });
        case constants.THEME_COLOR:
            return produce(state, draft => {
                draft.darkTheme = false;
                draft.themeColor = (action as ActionWithPayloadBase<ColorThemes>).payload;
            });
        case constants.DARK_THEME:
            return produce(state, draft => {
                draft.darkTheme = !state.darkTheme;
            });
        case constants.DRAWER_TYPE:
            return produce(state, draft => {
                draft.drawerType = (action as ActionWithPayloadBase<DrawerTypes>).payload;
            });
        default:
            return state;
    }
    return state;
}

export default settingsReducer;