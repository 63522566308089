import { all, call, put, takeEvery } from "redux-saga/effects";
import people from "./people";
import places from "./places";
import stories from "./stories";
import story from "./story";
import instruments from "./instruments";

import ajax from "../../util/api";
import * as constants from "../../constants/repression";
import RepressionHomepageModel from "../../models/repression/RepressionHomepageModel";
import { push } from "connected-react-router";

function* getHomepage() {
    try {
        const homepage = yield call(ajax.get, "/repression-homepage?include=photos,quotes");
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "content",
            payload: new RepressionHomepageModel(homepage.data)
        });
    } catch (error) {
        if (error.response) {
            switch (error.status) {
                case 404:
                    yield put(push("/404"));
                    break;
                default:
                    yield put(push("/500"));
                    break;
            }
        } else {
            yield put(push("/500"));
        }
    } finally {
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "isLoading",
            payload: false
        });
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.GET_HOMEPAGE, getHomepage),
        people(),
        places(),
        stories(),
        story(),
        instruments()
    ]);
}