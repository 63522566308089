export default class UserForUpdateModel {
    username: string;
    email: string;

    constructor(obj?: UserForUpdateModel) {
        this.username = obj?.username ?? "";
        this.email = obj?.email ?? "";
    }
}

export const getDefaultUserForUpdateModel: UserForUpdateModel = {
    username: "",
    email: "",
}