import { AuthActions } from "../../actions/auth";
import User, { getDefaultUser } from "../../models/auth/IUser";
import * as constants from "../../constants/auth";
import produce from "immer";
import { ActionWithPayloadBase } from "../../actions/actionWithPayloadBase";
import { combineReducers } from "redux";
import loginReducer, { getDefaultLogin, Login } from "./login";

export interface GeneralInfo {
    user: User;
    initUrl: string;
}

export const getDefaultGeneralInfo: GeneralInfo = {
    user: getDefaultUser,
    initUrl: ''
}

export interface Auth {
    generalInfo: GeneralInfo;
    login: Login;
}

export const getDefaultAuth: Auth = {
    generalInfo: getDefaultGeneralInfo,
    login: getDefaultLogin
}

const authReducer = (state: GeneralInfo = getDefaultGeneralInfo, action: AuthActions): GeneralInfo => {
    switch (action.type) {
        case constants.INIT_URL:
            return produce(state, draft => {
                draft.initUrl = (action as ActionWithPayloadBase<string>).payload;
            });
        case constants.SET_USER:
            return produce(state, draft => {
                draft.user = (action as ActionWithPayloadBase<User>).payload;
            });
        case constants.SIGNOUT_USER_SUCCESS:
            return produce(state, draft => {
                draft.user = getDefaultUser;
                draft.initUrl = "/";
            });
        default:
            return state;
    }
}

export default combineReducers({
    generalInfo: authReducer,
    login: loginReducer
});
