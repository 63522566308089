import produce from "immer";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../../../actions/actionWithDataPathBase";
import { UsersActions } from "../../../../actions/administration/security/users";
import * as constants from "../../../../constants/administration/security/users";

export interface Users {
    add: {
        isOpen: boolean;
        isLoading: boolean;
    },
    edit: {
        isOpen: boolean;
        isLoading: boolean;
        errors: string | null;
        id: number | null;
    },
    change_password: {
        isOpen: boolean;
        isLoading: boolean;
        errors: string | null;
        id: number | null;
    },
    delete: {
        isOpen: boolean;
        isLoading: boolean;
        errors: string | null;
        id: number | null;
    }
}

export const getDefaultUsers: Users = {
    add: {
        isOpen: false,
        isLoading: false
    },
    edit: {
        isOpen: false,
        isLoading: false,
        errors: null,
        id: null
    },
    change_password: {
        isOpen: false,
        isLoading: false,
        errors: null,
        id: null
    },
    delete: {
        isOpen: false,
        isLoading: false,
        errors: null,
        id: null
    }
};

const usersReducer = (state: Users = getDefaultUsers, action: UsersActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.ADD_SUBMIT:
            return produce(state, draft => {
                draft.add.isLoading = true;
            });
        case constants.INIT_EDIT:
            return produce(state, draft => {
                draft.edit.isOpen = true;
                draft.edit.id = (action as ActionWithDataPathBase<number>).payload;
            });
        case constants.EDIT_SUBMIT:
            return produce(state, draft => {
                draft.edit.isLoading = true;
            });
        case constants.INIT_CHANGE_PASSWORD:
            return produce(state, draft => {
                draft.change_password.isOpen = true;
                draft.change_password.id = (action as ActionWithDataPathBase<number>).payload;
            });
        case constants.CHANGE_PASSWORD_SUBMIT:
            return produce(state, draft => {
                draft.change_password.isLoading = true;
            });
        case constants.INIT_DELETE:
            return produce(state, draft => {
                draft.delete.isOpen = true;
                draft.delete.id = (action as ActionWithDataPathBase<number>).payload;
            });
        case constants.DELETE_SUBMIT:
            return produce(state, draft => {
                draft.delete.isLoading = true;
            });
        default:
            return state;
    }
}

export default usersReducer;