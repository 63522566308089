export default interface UserForCreationModel {
    username: string;
    email: string;
    password: string;
    password_confirmation: string;
}

export const getDefaultUserForCreationModel: UserForCreationModel = {
    username: "",
    email: "",
    password: "",
    password_confirmation: ""
}