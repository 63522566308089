export default class PeoplePhoto {
    order_no: number;
    show_in_header: boolean;
    description: string;
    subtitle: string;
    url: string;

    constructor(obj?: PeoplePhoto) {
        this.order_no = obj?.order_no;
        this.show_in_header = obj?.show_in_header;
        this.description = obj?.description;
        this.subtitle = obj?.subtitle;
        this.url = obj?.url;
    }
}