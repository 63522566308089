export class PeopleVideoForCreateModel {
    people_id: number;
    url: string;
    order_no: number;

    constructor(people_id: number) {
        this.people_id = people_id;
    }
}

export class PeopleVideoForUpdateModel extends PeopleVideoForCreateModel {
    id: number;

    constructor(obj?: PeopleVideoForUpdateModel) {
        super(obj?.people_id);

        this.url = obj?.url;
        this.order_no = obj?.order_no;
        this.id = obj?.id;
    }
}