import produce from "immer";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../../actions/actionWithDataPathBase";
import { DictionariesVictimsActions } from "../../../actions/dictionaries/victims";
import * as constants from "../../../constants/dictionaries/victims";
import VictimsDisplayMode from "../../../enums/dictionaries/victimsDisplayMode";
import DictionaryVictimModel from "../../../models/dictionaries/DictionaryVictimModel";

export interface Victims {
    mode: VictimsDisplayMode;
    isOpen: boolean;
    items: Array<DictionaryVictimModel>;
    isLoading: boolean;
};

export const victimsDefaultState: Victims = {
    mode: VictimsDisplayMode.List,
    isOpen: false,
    items: [],
    isLoading: false
}

const victimsReducer = (state: Victims = victimsDefaultState, action: DictionariesVictimsActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.GET_ITEMS:
        case constants.SEARCH:
            return produce(state, draft => {
                draft.isLoading = true;
                draft.mode = VictimsDisplayMode.List;
            });
        default:
            return state;
    }
}

export default victimsReducer;