import produce from "immer";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../../actions/actionWithDataPathBase";
import { RepressionPeopleActions } from "../../../actions/repression/people"
import * as constants from "../../../constants/repression/people";
import PeopleHomepageModel from "../../../models/administration/core/people/PeopleHomepageModel";

export interface People {
    isLoading: boolean;
    content: PeopleHomepageModel;
}

export const getDefaultPeople: People = {
    isLoading: false,
    content: null
}

const peopleReducer = (state: People = getDefaultPeople, action: RepressionPeopleActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.GET_PEOPLE:
            return produce(state, draft => {
                draft.isLoading = true;
            });
        default:
            return state;
    }
}

export default peopleReducer;