import produce from "immer";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../actions/actionWithDataPathBase";
import { RepressionActions } from "../../actions/about";
import * as constants from "../../constants/about";
import AboutFrontModel from "../../models/about/AboutFrontModel";

export interface About {
    isLoading: boolean;
    content: AboutFrontModel;
}

export const getDefaultAbout: About = {
    isLoading: false,
    content: null
}

const aboutReducer = (state: About = getDefaultAbout, action: RepressionActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.GET_ABOUT:
            return produce(state, draft => {
                draft.isLoading = true;
            });
        default:
            return state;
    }
}

export default aboutReducer;