import PlaceCategoryModel from "./PlaceCategoryModel";

export default class PlaceModel {
    id: number;
    name: string;
    coordinates: {
        longitude: number;
        latitude: number;
    };
    category: PlaceCategoryModel;

    constructor(obj?: any) {
        this.id = obj?.id;
        this.name = obj?.name;
        this.coordinates = {
            longitude: obj?.longitude ?? null,
            latitude: obj?.latitude ?? null
        }
        this.category = obj?.category ? new PlaceCategoryModel(obj?.category) : null;
    }
}