import produce from "immer";
import get from "lodash/get";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../actions/actionWithDataPathBase";
import { ActionWithPayloadBase } from "../../actions/actionWithPayloadBase";
import { VideoGalleryActions } from "../../actions/videoGallery";
import * as constants from "../../constants/videoGallery";
import IVideoGallery from "../../models/common/IVideoGallery";

export interface VideoGallery {
    [key: string]: IVideoGallery;
}

export const getDefaultVideoGallery: VideoGallery = {

};

const videoGallery = (state: VideoGallery = getDefaultVideoGallery, action: VideoGalleryActions) => {
    switch (action.type) {
        case constants.PUT_NEW_GALLERY:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.REMOVE_GALLERY:
            return produce(state, draft => {
                const { payload } = (action as ActionWithPayloadBase<string>);
                delete draft[payload];
            });
        case constants.GET_REQUEST:
            return produce(state, draft => {
                const { dataPath } = (action as ActionWithDataPathBase<string>);
                const gallery = get(draft, dataPath);
                if (gallery) {
                    gallery.isLoading = true;
                    gallery.values = [];
                    gallery.error = null;
                }
            });
        case constants.GET_SUCCESS:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                const gallery = get(draft, dataPath);
                if (gallery) {
                    gallery.isLoading = false;
                    gallery.values = payload.data;
                    gallery.total = payload.total;
                }
            });
        case constants.GET_FAILURE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<string>);
                const gallery = get(draft, dataPath);
                if (gallery) {
                    gallery.isLoading = false;
                    gallery.error = payload;
                }
            });
        case constants.CHANGE_PAGE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<number>);
                const gallery = get(draft, dataPath);
                if (gallery) {
                    gallery.crtPage = payload;
                }
            });
        default:
            return state
    }
}

export default videoGallery;