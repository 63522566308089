import filter from "lodash/filter";

export default class DictionaryPrisonModel {
    id: number;
    name: string;
    start_year: number;
    end_year: number;
    managers: Array<ManagerModel>;
    prisoners: Array<PrisonerModel>;

    constructor(obj?: any) {
        this.id = obj?.id;
        this.name = obj?.name;
        this.start_year = obj?.start_year;
        this.end_year = obj?.end_year;
        this.managers = obj?.management ? obj?.management.map((el: any) => new ManagerModel(el)) : [];
        this.prisoners = obj?.prisoners ? filter(obj?.prisoners, (el: any) => el.is_published).map((el: any) => new PrisonerModel(el)) : [];
    }
}

class ManagerModel {
    last_name: string;
    first_name: string;
    position: string;
    start_year: number;
    end_year: number;

    constructor(obj?: ManagerModel) {
        this.last_name = obj?.last_name;
        this.first_name = obj?.first_name;
        this.position = obj?.position;
        this.start_year = obj?.start_year;
        this.end_year = obj?.end_year;
    }
}

class PrisonerModel {
    id: number;
    last_name: string;
    first_name: string;
    imprisonments: string;
    is_published: boolean;
    show_in_slider: boolean;

    constructor(obj?: any) {
        this.id = obj?.id;
        this.last_name = obj?.last_name;
        this.first_name = obj?.first_name;
        this.imprisonments = obj?.imprisonments;
        this.is_published = obj?.is_published;
        this.show_in_slider = obj?.show_in_slider;
    }
}