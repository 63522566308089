export interface ILoginForm {
    username: string;
    password: string;
}

const formDef: ILoginForm = {
    username: "",
    password: ""
}

export default formDef;