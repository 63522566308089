import produce from "immer";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../../../actions/actionWithDataPathBase";
import { ActionWithPayloadBase } from "../../../../actions/actionWithPayloadBase";
import { PlacesActions } from "../../../../actions/administration/core/places";
import * as constants from "../../../../constants/administration/core/places";

export interface Places {
    homepage: {
        gettingData: boolean;
        isLoading: boolean;
        errors: string;
    },
    add: {
        isOpen: boolean;
        isLoading: boolean;
    },
    edit: {
        isLoading: boolean;
        errors: string | null;
    },
    delete: {
        isOpen: boolean;
        isLoading: boolean;
        errors: string | null;
        id: number | null;
    },
    workers: {
        add: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
        },
        edit: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
        },
        delete: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
            id: number | null;
        }
    },
    management: {
        add: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
        },
        edit: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
        },
        delete: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
            id: number | null;
            place_id: number | null;
        }
    },
}

export const getDefaultPlaces: Places = {
    homepage: {
        gettingData: false,
        isLoading: false,
        errors: null
    },
    add: {
        isOpen: false,
        isLoading: false
    },
    edit: {
        isLoading: false,
        errors: null
    },
    delete: {
        isOpen: false,
        isLoading: false,
        errors: null,
        id: null
    },
    workers: {
        add: {
            isOpen: false,
            isLoading: false,
            errors: null
        },
        edit: {
            isOpen: false,
            isLoading: false,
            errors: null
        },
        delete: {
            isOpen: false,
            isLoading: false,
            errors: null,
            id: null
        }
    },
    management: {
        add: {
            isOpen: false,
            isLoading: false,
            errors: null
        },
        edit: {
            isOpen: false,
            isLoading: false,
            errors: null
        },
        delete: {
            isOpen: false,
            isLoading: false,
            errors: null,
            id: null,
            place_id: null
        }
    },
};

const placesReducer = (state: Places = getDefaultPlaces, action: PlacesActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.INIT_ADD:
            return produce(state, draft => {
                draft.add.isOpen = true;
            });
        case constants.ADD_SUBMIT:
            return produce(state, draft => {
                draft.add.isLoading = true;
            });
        case constants.EDIT_SUBMIT:
            return produce(state, draft => {
                draft.edit.isLoading = true;
            });
        case constants.INIT_DELETE:
            return produce(state, draft => {
                draft.delete.isOpen = true;
                draft.delete.id = (action as ActionWithDataPathBase<number>).payload;
            });
        case constants.DELETE_SUBMIT:
            return produce(state, draft => {
                draft.delete.isLoading = true;
            });
        case constants.GET_HOMEPAGE:
            return produce(state, draft => {
                draft.homepage.gettingData = true;
            });
        case constants.SAVE_HOMEPAGE:
            return produce(state, draft => {
                draft.homepage.isLoading = true;
            });
        case constants.INIT_WORKERS_ADD:
            return produce(state, draft => {
                draft.workers.add.isOpen = true;
            });
        case constants.ADD_WORKERS_SUBMIT:
            return produce(state, draft => {
                draft.workers.add.isLoading = true;
            });
        case constants.INIT_WORKERS_EDIT:
            return produce(state, draft => {
                draft.workers.edit.isOpen = true;
            });
        case constants.EDIT_WORKERS_SUBMIT:
            return produce(state, draft => {
                draft.workers.edit.isLoading = true;
            });
        case constants.INIT_WORKERS_DELETE:
            return produce(state, draft => {
                draft.workers.delete.isOpen = true;
                const { payload } = (action as ActionWithPayloadBase<number>);
                draft.workers.delete.id = payload;
            });
        case constants.DELETE_WORKERS_SUBMIT:
            return produce(state, draft => {
                draft.workers.delete.isLoading = true;
            });
        case constants.INIT_MANAGEMENT_ADD:
            return produce(state, draft => {
                draft.management.add.isOpen = true;
            });
        case constants.ADD_MANAGEMENT_SUBMIT:
            return produce(state, draft => {
                draft.management.add.isLoading = true;
            });
        case constants.INIT_MANAGEMENT_EDIT:
            return produce(state, draft => {
                draft.management.edit.isOpen = true;
            });
        case constants.EDIT_MANAGEMENT_SUBMIT:
            return produce(state, draft => {
                draft.management.edit.isLoading = true;
            });
        case constants.INIT_MANAGEMENT_DELETE:
            return produce(state, draft => {
                draft.management.delete.isOpen = true;
                const { payload } = (action as ActionWithPayloadBase<{
                    place_id: number;
                    id: number
                }>);
                draft.management.delete.id = payload.id;
                draft.management.delete.place_id = payload.place_id;
            });
        case constants.DELETE_MANAGEMENT_SUBMIT:
            return produce(state, draft => {
                draft.management.delete.isLoading = true;
            });
        default:
            return state;
    }
}

export default placesReducer;