import * as constants from "../../constants/photoGallery";
import { ActionWithDataPathBase } from "../actionWithDataPathBase";
import { ActionWithPayloadBase } from "../actionWithPayloadBase";

export function initNewGallery(dataPath: string, payload: string): ActionWithDataPathBase<string> {
    return {
        type: constants.INIT_NEW_GALLERY,
        dataPath,
        payload
    };
}

export function removeGallery(payload: string): ActionWithPayloadBase<string> {
    return {
        type: constants.REMOVE_GALLERY,
        payload
    }
}

export function getData(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.GET_REQUEST,
        dataPath,
        payload
    };
}

export function changePage(dataPath: string, payload: number): ActionWithDataPathBase<number> {
    return {
        type: constants.CHANGE_PAGE,
        dataPath,
        payload
    };
}

export type PhotoGalleryActions = ReturnType<
    typeof initNewGallery |
    typeof removeGallery |
    typeof getData |
    typeof changePage
>;