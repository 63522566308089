import { all, takeEvery, put, select, call } from "redux-saga/effects";
import { push } from "connected-react-router";
import ajax from "../../util/api";
import get from "lodash/get";
import * as constants from "../../constants/tables";
import { InitTableProps, TablesPayloadBase } from "../../actions/tables";
import { ActionWithDataPathBase } from "../../actions/actionWithDataPathBase";

export function* putNewTable(action: ActionWithDataPathBase<InitTableProps>) {
    yield put({
        type: constants.PUT_NEW_TABLE,
        dataPath: action.dataPath,
        payload: {
            isLoading: false,
            values: [],
            crtPage: 1,
            total: 0,
            rowsPerPage: 10,
            error: null,
            search: "",
            orderBy: action.payload && action.payload.orderBy ? action.payload.orderBy : []
        }
    });
}

// export function* addFilter(dataPath, newFilter) {
//     let { filter } = yield select(state => _.get(state, `tables.${dataPath}`));
//     filter = _.concat(filter, newFilter);
//     yield put({
//         type: constants.CHANGE_VALUE_AT_PATH,
//         payload: {
//             dataPath: `${dataPath}.filter`,
//             value: filter
//         }
//     });
// }

// export function* removeFilter(dataPath, filterToRemove) {
//     let { filter } = yield select(state => _.get(state, `tables.${dataPath}.filter`));
//     filter = _.remove(filter, el => _.has(el, filterToRemove));
//     yield put({
//         type: constants.CHANGE_VALUE_AT_PATH,
//         payload: {
//             dataPath: `${dataPath}.filter`,
//             value: filter
//         }
//     });
// }

function* getData(action: ActionWithDataPathBase<TablesPayloadBase>) {
    try {
        const { rowsPerPage, crtPage, orderBy, search } = yield select(state => get(state, `tables.${action.dataPath}`));

        let urlToCall: string = `${action.payload.url}`;
        if (urlToCall.includes("?")) {
            urlToCall = urlToCall + `&page[number]=${crtPage}&page[size]=${rowsPerPage}`;
        } else {
            urlToCall = urlToCall + `?page[number]=${crtPage}&page[size]=${rowsPerPage}`;
        }

        if (orderBy && orderBy[0]) {
            if (orderBy[0].dir === "asc") {
                urlToCall = urlToCall + `&sort=${orderBy[0].key}`;
            } else {
                urlToCall = urlToCall + `&sort=-${orderBy[0].key}`;
            }
        }

        if (search && search !== "") {
            urlToCall = urlToCall + `&filter[search]=${search}`;
        }

        const { data } = yield call(ajax.get, urlToCall);
        yield put({
            type: constants.GET_SUCCESS,
            dataPath: action.dataPath,
            payload: {
                response: data
            }
        });
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    yield put(push("/administration/404"));
                    break;
                default:
                    yield put({
                        type: constants.GET_FAILURE,
                        dataPath: action.dataPath,
                        payload: {
                            message: (error.response.data && error.response.data.message) ?? "Failed to load data"
                        }
                    });
                    break;
            }
        } else {
            yield put({
                type: constants.GET_FAILURE,
                dataPath: action.dataPath,
                payload: {
                    message: "Failed to load data"
                }
            });
        }
    }
}

// function* selectAll(action) {
//     try {
//         const { selectColumns, expand, filter } = yield select(state => _.get(state, `tables.${action.payload.dataPath}`));
//         const query = buildQuery({
//             select: selectColumns,
//             expand,
//             filter,
//             count: true
//         });
//         const { data } = yield call(ajax.get, `${action.payload.odataUrl}${query}`);
//         const crtSelected = yield select(state => _.get(state, `tables.${action.payload.dataPath}.selected`));
//         if (data["@odata.count"] === crtSelected.length) {
//             yield put({
//                 type: constants.CHANGE_VALUE_AT_PATH,
//                 payload: {
//                     dataPath: `${action.payload.dataPath}.selected`,
//                     value: []
//                 }
//             });
//         } else {
//             yield put({
//                 type: constants.CHANGE_VALUE_AT_PATH,
//                 payload: {
//                     dataPath: `${action.payload.dataPath}.selected`,
//                     value: data.value
//                 }
//             });
//         }
//     } catch (error) {
//         if (error.response) {
//             switch (error.response.status) {
//                 case 404:
//                     yield put(push("/administration/404"));
//                     break;
//                 default:
//                     yield put({
//                         type: constants.GET_FAILURE,
//                         payload: {
//                             dataPath: action.payload.dataPath,
//                             message: (error.response.data && error.response.data.message) ?? "Failed to select data"
//                         }
//                     });
//                     break;
//             }
//         } else {
//             yield put({
//                 type: constants.GET_FAILURE,
//                 payload: {
//                     dataPath: action.payload.dataPath,
//                     message: "Failed to select data"
//                 }
//             });
//         }
//     }
// }

export default function* rootSaga() {
    yield all([
        takeEvery(constants.GET_REQUEST, getData),
        takeEvery(constants.CHANGE_PAGE, getData),
        takeEvery(constants.CHANGE_ROWS_PER_PAGE, getData),
        takeEvery(constants.ORDER_BY_CLICK, getData),
        takeEvery(constants.SEARCH_CHANGE, getData),
        takeEvery(constants.INIT_NEW_TABLE, putNewTable)
        //takeEvery(constants.SELECT_ALL, selectAll)
    ]);
}