const MODULE = "DOCUMENT_VIEWER_";

export const PUT_NEW_VIEWER = `${MODULE}put-new-VIEWER`;
export type PUT_NEW_VIEWER = typeof PUT_NEW_VIEWER;
export const INIT_NEW_VIEWER = `${MODULE}INIT_NEW_VIEWER`;
export type INIT_NEW_VIEWER = typeof INIT_NEW_VIEWER;
export const REMOVE_VIEWER = `${MODULE}REMOVE_VIEWER`;
export type REMOVE_VIEWER = typeof REMOVE_VIEWER;

export const GET_REQUEST = `${MODULE}GET_REQUEST`;
export type GET_REQUEST = typeof GET_REQUEST;
export const GET_SUCCESS = `${MODULE}GET_SUCCESS`;
export type GET_SUCCESS = typeof GET_SUCCESS;
export const GET_FAILURE = `${MODULE}GET_FAILURE`;
export type GET_FAILURE = typeof GET_FAILURE;