import { FORM_ERROR } from "final-form";
import each from "lodash/each";
import set from "lodash/set";

export function handleFormErrors(response: any) {
    if (response && response.status) {
        switch (response.status) {
            case 400:
                return {
                    [FORM_ERROR]: response.data.message
                }
            case 422:
                const errors: {
                    [key: string]: string;
                } = {};
                each(response.data, (val: Array<string>, key: string) => {
                    if (key !== "debug")
                        set(errors, key, val[0]);
                });
                return errors;
            default:
                return {
                    [FORM_ERROR]: "something went wrong"
                };
        }
    } else {
        return {
            [FORM_ERROR]: "something went wrong"
        };
    }
}

export function handleRequestErrors(response: any) {
    if (response && response.status) {
        switch (response.status) {
            case 422:
                const errors: {
                    [key: string]: string;
                } = {};
                each(response.data, (val: Array<string>, key: string) => {
                    if (key !== "debug")
                        set(errors, key, val[0]);
                });
                return errors;
            case 404:
            case 500:
                return response.data;
            default:
                return "something went wrong";
        }
    }
    return "something went wrong";
}