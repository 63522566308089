const MODULE = "TABLES_";

export const PUT_NEW_TABLE = `${MODULE}PUT_NEW_TABLE`;
export type PUT_NEW_TABLE = typeof PUT_NEW_TABLE;
export const INIT_NEW_TABLE = `${MODULE}INIT_NEW_TABLE`;
export type INIT_NEW_TABLE = typeof INIT_NEW_TABLE;
export const REMOVE_TABLE = `${MODULE}REMOVE_TABLE`;
export type REMOVE_TABLE = typeof REMOVE_TABLE;

export const GET_REQUEST = `${MODULE}GET_REQUEST`;
export type GET_REQUEST = typeof GET_REQUEST;
export const GET_SUCCESS = `${MODULE}GET_SUCCESS`;
export type GET_SUCCESS = typeof GET_SUCCESS;
export const GET_FAILURE = `${MODULE}GET_FAILURE`;
export type GET_FAILURE = typeof GET_FAILURE;

export const CHANGE_PAGE = `${MODULE}CHANGE_PAGE`;
export type CHANGE_PAGE = typeof CHANGE_PAGE;
export const CHANGE_ROWS_PER_PAGE = `${MODULE}CHANGE_ROWS_PER_PAGE`;
export type CHANGE_ROWS_PER_PAGE = typeof CHANGE_ROWS_PER_PAGE;
export const SELECT_ITEM = `${MODULE}SELECT_ITEM`;
export type SELECT_ITEM = typeof SELECT_ITEM;
export const SELECT_ALL = `${MODULE}SELECT_ALL`;
export type SELECT_ALL = typeof SELECT_ALL;
export const ORDER_BY_CLICK = `${MODULE}ORDER_BY_CLICK`;
export type ORDER_BY_CLICK = typeof ORDER_BY_CLICK;

export const CHANGE_VALUE_AT_PATH = `${MODULE}CHANGE_VALUE_AT_PATH`;
export type CHANGE_VALUE_AT_PATH = typeof CHANGE_VALUE_AT_PATH;
export const SEARCH_CHANGE = `${MODULE}SEARCH_CHANGE`;
export type SEARCH_CHANGE = typeof SEARCH_CHANGE;