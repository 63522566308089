import * as constants from "../../constants/tables";
import { ActionWithDataPathBase } from "../actionWithDataPathBase";
import { ActionWithPayloadBase } from "../actionWithPayloadBase";

export interface TablesPayloadBase {
    url: string;
}

export function getData(dataPath: string, payload: TablesPayloadBase): ActionWithDataPathBase<TablesPayloadBase> {
    return {
        type: constants.GET_REQUEST,
        dataPath,
        payload
    };
}

export interface ChangePagePayload extends TablesPayloadBase {
    page: number;
}

export function changePage(dataPath: string, payload: ChangePagePayload): ActionWithDataPathBase<ChangePagePayload> {
    return {
        type: constants.CHANGE_PAGE,
        dataPath,
        payload
    };
}

export interface ChangeRowsPerPagePayload extends TablesPayloadBase {
    rowsPerPage: number;
}

export function changeRowsPerPage(dataPath: string, payload: ChangeRowsPerPagePayload): ActionWithDataPathBase<ChangeRowsPerPagePayload> {
    return {
        type: constants.CHANGE_ROWS_PER_PAGE,
        dataPath,
        payload
    };
}

export function selectItem(dataPath: string, payload: any): ActionWithDataPathBase<any> {
    return {
        type: constants.SELECT_ITEM,
        dataPath,
        payload
    };
}

export function selectAll(dataPath: string, payload: TablesPayloadBase): ActionWithDataPathBase<TablesPayloadBase> {
    return {
        type: constants.SELECT_ALL,
        dataPath,
        payload
    };
}

export interface OrderByClickPayload extends TablesPayloadBase {
    item: any;
}

export function orderByClick(dataPath: string, payload: OrderByClickPayload): ActionWithDataPathBase<OrderByClickPayload> {
    return {
        type: constants.ORDER_BY_CLICK,
        dataPath,
        payload
    };
}

export function changeValueAtPath(dataPath: string, payload: any): ActionWithDataPathBase<any> {
    return {
        type: constants.CHANGE_VALUE_AT_PATH,
        dataPath,
        payload
    };
}

export interface SearchChangePayload extends TablesPayloadBase {
    value: string;
}

export function searchChange(dataPath: string, payload: SearchChangePayload): ActionWithDataPathBase<SearchChangePayload> {
    return {
        type: constants.SEARCH_CHANGE,
        dataPath,
        payload
    }
}

export interface InitTableProps {
    orderBy?: Array<{
        key: string;
        dir: string;
    }>
}

export function initNewTable(dataPath: string, payload?: InitTableProps): ActionWithDataPathBase<InitTableProps> {
    return {
        type: constants.INIT_NEW_TABLE,
        dataPath,
        payload
    };
}

export function removeTable(payload: string): ActionWithPayloadBase<string> {
    return {
        type: constants.REMOVE_TABLE,
        payload
    }
}

export type TablesActions = ReturnType<
    typeof getData |
    typeof changePage |
    typeof changeRowsPerPage |
    typeof selectItem |
    typeof selectAll |
    typeof orderByClick |
    typeof changeValueAtPath |
    typeof searchChange |
    typeof initNewTable |
    typeof removeTable
>;