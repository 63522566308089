import * as constants from "../../../../constants/administration/core/places";
import { ActionWithCallback } from "../../../actionWithCallback";
import { ActionWithDataPathBase } from "../../../actionWithDataPathBase";
import { ActionWithPayloadBase } from "../../../actionWithPayloadBase";

export function changeValue(dataPath: string, payload: any): ActionWithDataPathBase<any> {
    return {
        type: constants.CHANGE_VALUE,
        dataPath,
        payload
    };
}

export function initAdd(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.INIT_ADD,
        dataPath,
        payload
    };
}

export function submitAdd(dataPath: string, payload: ActionWithCallback<null>): ActionWithDataPathBase<ActionWithCallback<null>> {
    return {
        type: constants.ADD_SUBMIT,
        dataPath,
        payload
    };
}

export function initEdit(dataPath: string, payload: number): ActionWithDataPathBase<number> {
    return {
        type: constants.INIT_EDIT,
        dataPath,
        payload
    };
}

export function submitEdit(dataPath: string, payload: ActionWithCallback<number>): ActionWithDataPathBase<ActionWithCallback<number>> {
    return {
        type: constants.EDIT_SUBMIT,
        dataPath,
        payload
    };
}

export function initDelete(payload: number): ActionWithPayloadBase<number> {
    return {
        type: constants.INIT_DELETE,
        payload
    };
}

export function submitDelete(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.DELETE_SUBMIT,
        dataPath,
        payload
    };
}

export function getHomepage(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.GET_HOMEPAGE,
        dataPath,
        payload
    };
}

export function saveHomepage(dataPath: string, payload: ActionWithCallback<null> = null): ActionWithDataPathBase<ActionWithCallback<null>> {
    return {
        type: constants.SAVE_HOMEPAGE,
        dataPath,
        payload
    };
}

export function initWorkersAdd(dataPath: string, payload: null): ActionWithDataPathBase<null> {
    return {
        type: constants.INIT_WORKERS_ADD,
        dataPath,
        payload
    };
}

export function submitWorkersAdd(dataPath: string, payload: ActionWithCallback<string>): ActionWithDataPathBase<ActionWithCallback<string>> {
    return {
        type: constants.ADD_WORKERS_SUBMIT,
        dataPath,
        payload
    };
}

export function initWorkersEdit(dataPath: string, payload: number): ActionWithDataPathBase<number> {
    return {
        type: constants.INIT_WORKERS_EDIT,
        dataPath,
        payload
    };
}

export function submitWorkersEdit(dataPath: string, payload: ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>): ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>> {
    return {
        type: constants.EDIT_WORKERS_SUBMIT,
        dataPath,
        payload
    };
}

export function initWorkersDelete(payload: number): ActionWithPayloadBase<number> {
    return {
        type: constants.INIT_WORKERS_DELETE,
        payload
    };
}

export function submitWorkersDelete(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.DELETE_WORKERS_SUBMIT,
        dataPath,
        payload
    };
}

export function initManagementAdd(dataPath: string, payload: number): ActionWithDataPathBase<number> {
    return {
        type: constants.INIT_MANAGEMENT_ADD,
        dataPath,
        payload
    };
}

export function submitManagementAdd(dataPath: string, payload: ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>): ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>> {
    return {
        type: constants.ADD_MANAGEMENT_SUBMIT,
        dataPath,
        payload
    };
}

export function initManagementEdit(dataPath: string, payload: {
    place_id: number;
    id: number
}): ActionWithDataPathBase<{
    place_id: number;
    id: number
}> {
    return {
        type: constants.INIT_MANAGEMENT_EDIT,
        dataPath,
        payload
    };
}

export function submitManagementEdit(dataPath: string, payload: ActionWithCallback<{
    id: number;
    place_id: number;
    tableDataPath: string;
}>): ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    place_id: number;
    tableDataPath: string;
}>> {
    return {
        type: constants.EDIT_MANAGEMENT_SUBMIT,
        dataPath,
        payload
    };
}

export function initManagementDelete(payload: {
    place_id: number;
    id: number
}): ActionWithPayloadBase<{
    place_id: number;
    id: number
}> {
    return {
        type: constants.INIT_MANAGEMENT_DELETE,
        payload
    };
}

export function submitManagementDelete(dataPath: string, payload: null = null): ActionWithDataPathBase<null> {
    return {
        type: constants.DELETE_MANAGEMENT_SUBMIT,
        dataPath,
        payload
    };
}

export function uplodadCategoryPhoto(dataPath: string, payload: {
    id: number;
    photo: File;
}): ActionWithDataPathBase<{
    id: number;
    photo: File;
}> {
    return {
        type: constants.UPLOAD_CATEGORY_PHOTO,
        dataPath,
        payload
    };
}

export type PlacesActions = ReturnType<
    typeof changeValue |
    typeof initAdd |
    typeof submitAdd |
    typeof initEdit |
    typeof submitEdit |
    typeof initDelete |
    typeof submitDelete |
    typeof getHomepage |
    typeof saveHomepage |
    typeof initWorkersAdd |
    typeof submitWorkersAdd |
    typeof initWorkersEdit |
    typeof submitWorkersEdit |
    typeof initWorkersDelete |
    typeof submitWorkersDelete |
    typeof initManagementAdd |
    typeof submitManagementAdd |
    typeof initManagementEdit |
    typeof submitManagementEdit |
    typeof initManagementDelete |
    typeof submitManagementDelete |
    typeof uplodadCategoryPhoto
>;