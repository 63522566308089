export default class AboutUsModel {
    introduction: string;
    content: string;
    content_left: string;
    content_right: string;

    constructor(obj?: AboutUsModel) {
        this.introduction = obj?.introduction;
        this.content = obj?.content;
        this.content_left = obj?.content_left;
        this.content_right = obj?.content_right;
    }
}