import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { ActionWithDataPathBase } from "../../actions/actionWithDataPathBase";
import ContactModel from "../../models/contact/ContactModel";
import { putNewForm } from "../forms";
import * as constants from "../../constants/contact";
import * as actions from "../../actions/contact";
import { ActionWithCallback } from "../../actions/actionWithCallback";
import { AppState } from "../../store/appState";
import get from "lodash/get";
import ajax from "../../util/api";
import { handleFormErrors } from "../../util/errorHandling";

function* initState(action: ActionWithDataPathBase<null>) {
    const model = new ContactModel();
    yield call(putNewForm, action.dataPath, model);
}

function* save(action: ActionWithDataPathBase<ActionWithCallback<null>>) {
    try {
        const formData: ContactModel = yield select((state: AppState) => get(state.forms, action.dataPath));
        yield call(ajax.post, "/contact", formData);
        if (action.payload.successCallback) {
            yield call(action.payload.successCallback);
        }
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("isLoading", false));
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.INIT_FORM, initState),
        takeEvery(constants.SUBMIT_FORM, save)
    ]);
}