import produce from "immer";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../actions/actionWithDataPathBase";
import { ContactActions } from "../../actions/contact"
import * as constants from "../../constants/contact";

export interface Contact {
    isLoading: boolean;
}

export const getDefaultContact: Contact = {
    isLoading: false
}

const contactReducer = (state: Contact = getDefaultContact, action: ContactActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.SUBMIT_FORM:
            return produce(state, draft => {
                draft.isLoading = true;
            });
        default:
            return state;
    }
}

export default contactReducer;