import { combineReducers } from "redux";
import prisons, { Prisons, prisonsDefaultState } from "./prisons";
import victims, { Victims, victimsDefaultState } from "./victims";

export interface Dictionaries {
    victims: Victims;
    prisons: Prisons;
}

export const dictionariesDefaultState: Dictionaries = {
    victims: victimsDefaultState,
    prisons: prisonsDefaultState
}

export default combineReducers({
    victims,
    prisons
});