import produce from "immer";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../../../actions/actionWithDataPathBase";
import { ActionWithPayloadBase } from "../../../../actions/actionWithPayloadBase";
import { PagesActions } from "../../../../actions/administration/core/pages";
import * as constants from "../../../../constants/administration/core/pages";

export interface Pages {
    isLoading: boolean;
    errors: string;
    photos: {
        add: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
        },
        edit: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
        },
        delete: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
            id: number | null;
            page_id: number | null;
        }
    },
    quotes: {
        add: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
        },
        edit: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
        },
        delete: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
            id: number | null;
            page_id: number | null;
        }
    },
}

export const getDefaultPages: Pages = {
    isLoading: false,
    errors: null,
    photos: {
        add: {
            isOpen: false,
            isLoading: false,
            errors: null
        },
        edit: {
            isOpen: false,
            isLoading: false,
            errors: null
        },
        delete: {
            isOpen: false,
            isLoading: false,
            errors: null,
            id: null,
            page_id: null
        }
    },
    quotes: {
        add: {
            isOpen: false,
            isLoading: false,
            errors: null
        },
        edit: {
            isOpen: false,
            isLoading: false,
            errors: null
        },
        delete: {
            isOpen: false,
            isLoading: false,
            errors: null,
            id: null,
            page_id: null
        }
    },
};

const pagesReducer = (state: Pages = getDefaultPages, action: PagesActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.SAVE_REPRESSION:
        case constants.SAVE_LANDING:
        case constants.SAVE_ABOUT:
        case constants.SAVE_TERMS:
        case constants.SAVE_PRIVACY:
            return produce(state, draft => {
                draft.isLoading = true;
            });
        case constants.INIT_PHOTO_ADD:
            return produce(state, draft => {
                draft.photos.add.isOpen = true;
            });
        case constants.ADD_PHOTO_SUBMIT:
            return produce(state, draft => {
                draft.photos.add.isLoading = true;
            });
        case constants.INIT_PHOTO_EDIT:
            return produce(state, draft => {
                draft.photos.edit.isOpen = true;
            });
        case constants.EDIT_PHOTO_SUBMIT:
            return produce(state, draft => {
                draft.photos.edit.isLoading = true;
            });
        case constants.INIT_PHOTO_DELETE:
            return produce(state, draft => {
                draft.photos.delete.isOpen = true;
                const { payload } = (action as ActionWithPayloadBase<{
                    page_id: number;
                    id: number
                }>);
                draft.photos.delete.id = payload.id;
                draft.photos.delete.page_id = payload.page_id;
            });
        case constants.DELETE_PHOTO_SUBMIT:
            return produce(state, draft => {
                draft.photos.delete.isLoading = true;
            });
        case constants.INIT_QUOTE_ADD:
            return produce(state, draft => {
                draft.quotes.add.isOpen = true;
            });
        case constants.ADD_QUOTE_SUBMIT:
            return produce(state, draft => {
                draft.quotes.add.isLoading = true;
            });
        case constants.INIT_QUOTE_EDIT:
            return produce(state, draft => {
                draft.quotes.edit.isOpen = true;
            });
        case constants.EDIT_QUOTE_SUBMIT:
            return produce(state, draft => {
                draft.quotes.edit.isLoading = true;
            });
        case constants.INIT_QUOTE_DELETE:
            return produce(state, draft => {
                draft.quotes.delete.isOpen = true;
                const { payload } = (action as ActionWithPayloadBase<{
                    page_id: number;
                    id: number
                }>);
                draft.quotes.delete.id = payload.id;
                draft.quotes.delete.page_id = payload.page_id;
            });
        case constants.DELETE_QUOTE_SUBMIT:
            return produce(state, draft => {
                draft.quotes.delete.isLoading = true;
            });
        default:
            return state;
    }
}

export default pagesReducer;