import produce from "immer";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../actions/actionWithDataPathBase";
import { RepressionActions } from "../../actions/privacy";
import * as constants from "../../constants/privacy";
import AboutFrontModel from "../../models/about/AboutFrontModel";

export interface Privacy {
    isLoading: boolean;
    content: AboutFrontModel;
}

export const getDefaultPrivacy: Privacy = {
    isLoading: false,
    content: null
}

const privacyReducer = (state: Privacy = getDefaultPrivacy, action: RepressionActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.GET_PRIVACY:
            return produce(state, draft => {
                draft.isLoading = true;
            });
        default:
            return state;
    }
}

export default privacyReducer;