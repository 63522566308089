import get from "lodash/get";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { ActionWithCallback } from "../../../../actions/actionWithCallback";
import { ActionWithDataPathBase } from "../../../../actions/actionWithDataPathBase";
import InstrumentsCategories from "../../../../enums/core/InstrumentsCategories";
import InstrumentForCreationModel from "../../../../models/administration/core/instruments/InstrumentForCreationModel";
import { AppState } from "../../../../store/appState";
import { putNewForm } from "../../../forms";
import ajax from "../../../../util/api";
import * as actions from "../../../../actions/administration/core/instruments";
import * as constants from "../../../../constants/administration/core/instruments";
import { push } from "connected-react-router";
import { handleFormErrors, handleRequestErrors } from "../../../../util/errorHandling";
import * as tableActions from "../../../../actions/tables";
import InstrumentForUpdateModel from "../../../../models/administration/core/instruments/InstrumentForUpdateModel";
import InstrumentPositionForCreationModel from "../../../../models/administration/core/instruments/InstrumentPositionForCreationModel";
import InstrumentPositionForUpdateModel from "../../../../models/administration/core/instruments/InstrumentPositionForUpdateModel";
import InstrumentsHomepageModel from "../../../../models/administration/core/instruments/InstrumentsHomepageModel";

function* initAdd(action: ActionWithDataPathBase<InstrumentsCategories>) {
    yield call(putNewForm, action.dataPath, new InstrumentForCreationModel(action.payload));
}

function* create(action: ActionWithDataPathBase<ActionWithCallback<InstrumentsCategories>>) {
    try {
        const formData: InstrumentForCreationModel = yield select((state: AppState) => get(state.forms, action.dataPath));
        const { data } = yield call(ajax.post, "/instruments", formData);
        let callbackUrl = null;
        switch (action.payload.data) {
            case InstrumentsCategories.Government:
                callbackUrl = "government";
                break;
            case InstrumentsCategories.MinistryOfInterior:
                callbackUrl = "ministry-of-interior";
                break;
            case InstrumentsCategories.Security:
                callbackUrl = "security";
                break;
            case InstrumentsCategories.PenitentiaryDirectorate:
                callbackUrl = "penitentiary-directorate";
                break;
            case InstrumentsCategories.RegionalSecurityDirectorates:
                callbackUrl = "regional-security-directorates";
                break;
            case InstrumentsCategories.OperationsOffice:
                callbackUrl = "operations-office";
                break;
            case InstrumentsCategories.PoliticalOfficers:
                callbackUrl = "political-officers";
                break;
            case InstrumentsCategories.PenitentiariesAndColonies:
                callbackUrl = "penitentiaries-and-colonies";
                break;
            case InstrumentsCategories.Party:
            default:
                callbackUrl = "party";
                break;
        }
        yield all([
            put(actions.changeValue("add.isOpen", false)),
            put(push(`/administration/instruments/${callbackUrl}/edit/${data.id}`))
        ]);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("add.isLoading", false));
    }
}

function* initEdit(action: ActionWithDataPathBase<number>) {
    try {
        const { data } = yield call(ajax.get, `/instruments/${action.payload}`);
        yield call(putNewForm, action.dataPath, new InstrumentForUpdateModel(data));
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    yield put(push("/administration/404"));
                    break;
                case 500:
                default:
                    yield put(push("/administration/500"));
                    break;
            }
        }
        const message = handleRequestErrors(error.response);
        yield put(actions.changeValue("edit.errors", message));
    }
}

function* update(action: ActionWithDataPathBase<ActionWithCallback<number>>) {
    try {
        const formData: InstrumentForUpdateModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        yield call(ajax.put, `/instruments/${action.payload.data}`, formData);
        if (action.payload.successCallback) {
            yield call(action.payload.successCallback);
        }
        yield put({
            type: constants.INIT_EDIT,
            dataPath: action.dataPath,
            payload: action.payload.data
        });
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("edit.isLoading", false));
    }
}

function* deleteInstrument(action: ActionWithDataPathBase<InstrumentsCategories>) {
    try {
        const { id } = yield select((state: AppState) => state.administration.core.instruments.delete);
        yield call(ajax.delete, `/instruments/${id}`);
        yield call(closeDialogAndRefreshGrid, "delete.isOpen", action.dataPath, action.payload);
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "delete", message);
    }
    finally {
        yield put(actions.changeValue("delete.isLoading", false));
    }
}

function* setDialogErrors(basePath: string, message: string) {
    yield all([
        put(actions.changeValue(`${basePath}.errors`, message)),
        put(actions.changeValue(`${basePath}.isOpen`, false))
    ]);
}

function* closeDialogAndRefreshGrid(dataPath: string, tableDataPath: string, category: InstrumentsCategories) {
    yield all([
        put(actions.changeValue(dataPath, false)),
        put(tableActions.getData(tableDataPath, {
            url: `/instruments?filter[instruments_category_id]=${category}`
        }))
    ]);
}

function* initPositionsAdd(action: ActionWithDataPathBase<number>) {
    yield call(putNewForm, action.dataPath, new InstrumentPositionForCreationModel(action.payload));
}

function* createPositions(action: ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>>) {
    try {
        const formData: InstrumentPositionForCreationModel = yield select((state: AppState) => get(state.forms, action.dataPath));
        yield call(ajax.post, `/instruments/${action.payload.data.id}/positions`, formData);
        yield call(closePositionsDialogAndRefreshGrid, "positions.add.isOpen", action.payload.data);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("positions.add.isLoading", false));
    }
}

function* initPositionsEdit(action: ActionWithDataPathBase<{
    instrument_id: number;
    id: number
}>) {
    try {
        const { data } = yield call(ajax.get, `/instruments/${action.payload.instrument_id}/positions/${action.payload.id}`);
        yield call(putNewForm, action.dataPath, new InstrumentPositionForUpdateModel(data));
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "positions.edit", message);
    }
}

function* updatePositions(action: ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    instrument_id: number;
    tableDataPath: string;
}>>) {
    try {
        const formData: InstrumentPositionForUpdateModel = yield select((state: AppState) => get(state.forms, action.dataPath));
        yield call(ajax.put, `/instruments/${action.payload.data.instrument_id}/positions/${action.payload.data.id}`, formData);
        yield call(closePositionsDialogAndRefreshGrid, "positions.edit.isOpen", {
            id: action.payload.data.instrument_id,
            tableDataPath: action.payload.data.tableDataPath
        });
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("positions.edit.isLoading", false));
    }
}

function* deletePositions(action: ActionWithDataPathBase<null>) {
    try {
        const { id, instrument_id } = yield select((state: AppState) => state.administration.core.instruments.positions.delete);
        yield call(ajax.delete, `/instruments/${instrument_id}/positions/${id}`);
        yield call(closePositionsDialogAndRefreshGrid, "positions.delete.isOpen", {
            id: instrument_id,
            tableDataPath: action.dataPath
        });
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "positions.delete", message);
    }
    finally {
        yield put(actions.changeValue("positions.delete.isLoading", false));
    }
}

function* closePositionsDialogAndRefreshGrid(dataPath: string, payload: {
    id: number;
    tableDataPath: string;
}) {
    yield all([
        put(actions.changeValue(dataPath, false)),
        put(tableActions.getData(payload.tableDataPath, {
            url: `/instruments/${payload.id}/positions`
        }))
    ]);
}

function* getHomepage(action: ActionWithDataPathBase<null>) {
    try {
        const { data } = yield call(ajax.get, "/instruments-homepage");
        yield call(putNewForm, action.dataPath, new InstrumentsHomepageModel(data));
        yield put(actions.changeValue("homepage.gettingData", false));
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    yield put(push("/administration/404"));
                    break;
                case 500:
                default:
                    yield put(push("/administration/500"));
                    break;
            }
        }
        const message = handleRequestErrors(error.response);
        yield put(actions.changeValue("homepage.errors", message));
    }
}

function* updateHomepage(action: ActionWithDataPathBase<ActionWithCallback<null>>) {
    try {
        const formData: InstrumentsHomepageModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        yield call(ajax.post, `/instruments-homepage`, formData);
        if (action.payload.successCallback) {
            yield call(action.payload.successCallback);
        }
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("homepage.isLoading", false));
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.INIT_ADD, initAdd),
        takeEvery(constants.ADD_SUBMIT, create),
        takeEvery(constants.INIT_EDIT, initEdit),
        takeEvery(constants.EDIT_SUBMIT, update),
        takeEvery(constants.DELETE_SUBMIT, deleteInstrument),
        takeEvery(constants.INIT_POSITIONS_ADD, initPositionsAdd),
        takeEvery(constants.ADD_POSITIONS_SUBMIT, createPositions),
        takeEvery(constants.INIT_POSITIONS_EDIT, initPositionsEdit),
        takeEvery(constants.EDIT_POSITIONS_SUBMIT, updatePositions),
        takeEvery(constants.DELETE_POSITIONS_SUBMIT, deletePositions),
        takeEvery(constants.GET_HOMEPAGE, getHomepage),
        takeEvery(constants.SAVE_HOMEPAGE, updateHomepage)
    ]);
}