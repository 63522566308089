import produce from "immer";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../actions/actionWithDataPathBase";
import { RepressionActions } from "../../actions/landing";
import * as constants from "../../constants/landing";
import LandingFrontModel from "../../models/landing/LandingFrontModel";

export interface Landing {
    isLoading: boolean;
    content: LandingFrontModel;
}

export const getDefaultLanding: Landing = {
    isLoading: false,
    content: null
}

const landingReducer = (state: Landing = getDefaultLanding, action: RepressionActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.GET_HOMEPAGE:
            return produce(state, draft => {
                draft.isLoading = true;
            });
        default:
            return state;
    }
}

export default landingReducer;