export default class RepressionPhotoModel {
    order_no: number;
    url: string;
    id: number;

    constructor(obj?: RepressionPhotoModel) {
        this.order_no = obj?.order_no;
        this.url = obj?.url;
        this.id = obj?.id;
    }
}