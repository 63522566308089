import produce from "immer";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../actions/actionWithDataPathBase";
import { searchActions } from "../../actions/search";
import SearchModel from "../../models/search/SearchModel";
import * as constants from "../../constants/search";

export interface Search {
    isLoading: boolean;
    items: Array<SearchModel>;
}

export const getDefaultSearch: Search = {
    isLoading: false,
    items: []
}

const searchReducer = (state: Search = getDefaultSearch, action: searchActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.GET_RESULTS:
            return produce(state, draft => {
                draft.isLoading = true;
            });
        default:
            return state;
    }
}

export default searchReducer;