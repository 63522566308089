export default class PlacesWorkerForCreateModel {
    name: string;
    convicted: number;
    hospitalized: number;

    constructor(obj?: PlacesWorkerForCreateModel) {
        this.name = obj?.name;
        this.convicted = obj?.convicted;
        this.hospitalized = obj?.hospitalized;
    }
}