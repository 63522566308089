export default class ContactModel {
    name: string;
    email: string;
    message: string;
    terms: boolean;

    constructor(obj?: ContactModel) {
        this.name = obj?.name ?? null;
        this.email = obj?.email ?? null;
        this.message = obj?.message ?? null;
        this.terms = obj?.terms ?? false;
    }
}