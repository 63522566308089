enum InstrumentsCategories {
    Party = 1,
    Government = 2,
    MinistryOfInterior = 3,
    Security = 4,
    PenitentiaryDirectorate = 5,
    RegionalSecurityDirectorates = 6,
    OperationsOffice = 7,
    PoliticalOfficers = 8,
    PenitentiariesAndColonies = 9,
    Militia = 10
}

export default InstrumentsCategories;