import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";

import settings from "./settings";
import auth from "./auth";
import forms from "./forms";
import tables from "./tables";
import administration from "./administration";
import photoGallery from "./photoGallery";
import videoGallery from "./videoGallery";
import documentViewer from "./documentViewer";
import repression from "./repression";
import dictionaries from "./dictionaries";
import landing from "./landing";
import search from "./search";
import about from "./about";
import contact from "./contact";
import terms from "./terms";
import privacy from "./privacy";

export default (history: History) => combineReducers({
    about,
    administration,
    auth,
    documentViewer,
    forms,
    photoGallery,
    settings,
    tables,
    repression,
    router: connectRouter(history),
    videoGallery,
    dictionaries,
    landing,
    search,
    contact,
    terms,
    privacy
});
