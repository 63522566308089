import { combineReducers } from "redux";
import usersReducer, { getDefaultUsers, Users } from "./users";

export interface Security {
    users: Users;
}

export const getDefaultSecurity: Security = {
    users: getDefaultUsers
}

export default combineReducers({
    users: usersReducer
});