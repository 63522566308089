import produce from "immer";
import get from "lodash/get";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../actions/actionWithDataPathBase";
import { ActionWithPayloadBase } from "../../actions/actionWithPayloadBase";
import { DocumentViewerActions } from "../../actions/documentViewer";
import * as constants from "../../constants/documentViewer";
import IDocumentViewer from "../../models/common/IDocumentViewer";

export interface DocumentViewer {
    [key: string]: IDocumentViewer;
}

export const getDefaultDocumentViewer: DocumentViewer = {

};

const photoGallery = (state: DocumentViewer = getDefaultDocumentViewer, action: DocumentViewerActions) => {
    switch (action.type) {
        case constants.PUT_NEW_VIEWER:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.REMOVE_VIEWER:
            return produce(state, draft => {
                const { payload } = (action as ActionWithPayloadBase<string>);
                delete draft[payload];
            });
        case constants.GET_REQUEST:
            return produce(state, draft => {
                const { dataPath } = (action as ActionWithDataPathBase<string>);
                const viewer = get(draft, dataPath);
                if (viewer) {
                    viewer.isLoading = true;
                    viewer.value = null;
                    viewer.error = null;
                }
            });
        case constants.GET_SUCCESS:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                const viewer = get(draft, dataPath);
                if (viewer) {
                    viewer.isLoading = false;
                    viewer.value = payload;
                }
            });
        case constants.GET_FAILURE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<string>);
                const viewer = get(draft, dataPath);
                if (viewer) {
                    viewer.isLoading = false;
                    viewer.error = payload;
                }
            });
        default:
            return state
    }
}

export default photoGallery;