import { push } from "connected-react-router";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { ActionWithPayloadBase } from "../../../actions/actionWithPayloadBase";
import * as constants from "../../../constants/repression/story";
import Story from "../../../models/repression/People/story";
import ajax from "../../../util/api";

function* getStory(action: ActionWithPayloadBase<number>) {
    try {
        const { data } = yield call(ajax.get, `/people/${action.payload}?include=photos,videos,document&append=story_url`);
        if (data) {
            yield put({
                type: constants.CHANGE_VALUE,
                dataPath: "data",
                payload: new Story(data)
            });
        }
    } catch (error) {
        if (error.response) {
            switch (error.status) {
                case 404:
                    yield put(push("/404"));
                    break;
                default:
                    yield put(push("/500"));
                    break;
            }
        } else {
            yield put(push("/500"));
        }
    } finally {
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "isLoading",
            payload: false
        });
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.GET_STORY, getStory)
    ]);
}