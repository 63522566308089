import get from "lodash/get";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { ActionWithCallback } from "../../../../actions/actionWithCallback";
import { ActionWithDataPathBase } from "../../../../actions/actionWithDataPathBase";
import * as constants from "../../../../constants/administration/core/places";
import * as actions from "../../../../actions/administration/core/places";
import PlacesForCreationModel from "../../../../models/administration/core/places/PlacesForCreationModel";
import { AppState } from "../../../../store/appState";
import { putNewForm } from "../../../forms";
import ajax from "../../../../util/api";
import { push } from "connected-react-router";
import { handleFormErrors, handleRequestErrors } from "../../../../util/errorHandling";
import PlacesForUpdateModel from "../../../../models/administration/core/places/PlacesForUpdateModel";
import * as tableActions from "../../../../actions/tables";
import PlacesHomepageModel from "../../../../models/administration/core/places/PlacesHomepageModel";
import PlacesWorkerForCreateModel from "../../../../models/administration/core/places/PlacesWorkerForCreationModel";
import PlacesWorkerForUpdateModel from "../../../../models/administration/core/places/PlacesWorkerForUpdateModel";
import PlacesManagementForCreationModel from "../../../../models/administration/core/places/PlacesManagementForCreationModel";
import PlacesManagementForUpdateModel from "../../../../models/administration/core/places/PlacesManagementForUpdateModel";

function* initAdd(action: ActionWithDataPathBase<null>) {
    yield call(putNewForm, action.dataPath, new PlacesForCreationModel());
}

function* create(action: ActionWithDataPathBase<ActionWithCallback<null>>) {
    try {
        const formData: PlacesForCreationModel = yield select((state: AppState) => get(state.forms, action.dataPath));
        const { data } = yield call(ajax.post, "/places", formData);
        yield all([
            put(actions.changeValue("add.isOpen", false)),
            put(push(`/administration/places/prisons/edit/${data.id}`))
        ]);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("add.isLoading", false));
    }
}

function* initEdit(action: ActionWithDataPathBase<number>) {
    try {
        const { data } = yield call(ajax.get, `/places/${action.payload}`);
        yield call(putNewForm, action.dataPath, new PlacesForUpdateModel(data));
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    yield put(push("/administration/404"));
                    break;
                case 500:
                default:
                    yield put(push("/administration/500"));
                    break;
            }
        }
        const message = handleRequestErrors(error.response);
        yield put(actions.changeValue("edit.errors", message));
    }
}

function* update(action: ActionWithDataPathBase<ActionWithCallback<number>>) {
    try {
        const formData: PlacesForUpdateModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        yield call(ajax.put, `/places/${action.payload.data}`, {
            latitude: formData.coordinates.latitude,
            longitude: formData.coordinates.longitude,
            ...formData
        });
        if (action.payload.successCallback) {
            yield call(action.payload.successCallback);
        }
        yield put({
            type: constants.INIT_EDIT,
            dataPath: action.dataPath,
            payload: action.payload.data
        });
    }
    catch (error) {
        if (action.payload.errorCallback) {
            if (error.response) {
                const errors = handleFormErrors(error.response);
                yield call(action.payload.errorCallback, errors);
            } else {
                yield put({
                    type: constants.CHANGE_VALUE,
                    dataPath: "errors",
                    value: "something went wrong"
                });
            }

        }
    }
    finally {
        yield put(actions.changeValue("edit.isLoading", false));
    }
}

function* deletePlace(action: ActionWithDataPathBase<null>) {
    try {
        const { id } = yield select((state: AppState) => state.administration.core.places.delete);
        yield call(ajax.delete, `/places/${id}`);
        yield call(closeDialogAndRefreshGrid, "delete.isOpen", action.dataPath);
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "delete", message);
    }
    finally {
        yield put(actions.changeValue("delete.isLoading", false));
    }
}

function* setDialogErrors(basePath: string, message: string) {
    yield all([
        put(actions.changeValue(`${basePath}.errors`, message)),
        put(actions.changeValue(`${basePath}.isOpen`, false))
    ]);
}

function* closeDialogAndRefreshGrid(dataPath: string, tableDataPath: string) {
    yield all([
        put(actions.changeValue(dataPath, false)),
        put(tableActions.getData(tableDataPath, {
            url: "/places?include=category"
        }))
    ]);
}

function* getHomepage(action: ActionWithDataPathBase<null>) {
    try {
        const { data } = yield call(ajax.get, "/places-homepage?append=map_base_url");
        yield call(putNewForm, action.dataPath, new PlacesHomepageModel(data));
        yield put(actions.changeValue("homepage.gettingData", false));
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    yield put(push("/administration/404"));
                    break;
                case 500:
                default:
                    yield put(push("/administration/500"));
                    break;
            }
        }
        const message = handleRequestErrors(error.response);
        yield put(actions.changeValue("homepage.errors", message));
    }
}

function* updateHomepage(action: ActionWithDataPathBase<ActionWithCallback<null>>) {
    try {
        const formData: PlacesHomepageModel = yield select((state: AppState) => get(state.forms, action.dataPath));

        const formToSend = new FormData();
        if (formData.map_base) {
            formToSend.append("map_base", formData.map_base);
        }
        formToSend.append('content', formData.content);

        yield call(ajax.post, `/places-homepage`, formToSend);
        if (action.payload.successCallback) {
            yield call(action.payload.successCallback);
        }

        yield put({
            type: constants.GET_HOMEPAGE,
            dataPath: action.dataPath,
        });
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("homepage.isLoading", false));
    }
}

function* initWorkersAdd(action: ActionWithDataPathBase<number>) {
    yield call(putNewForm, action.dataPath, new PlacesWorkerForCreateModel());
}

function* createWorker(action: ActionWithDataPathBase<ActionWithCallback<string>>) {
    try {
        const formData: PlacesWorkerForCreateModel = yield select((state: AppState) => get(state.forms, action.dataPath));
        yield call(ajax.post, `/places-workers`, formData);
        yield call(closeWorkersDialogAndRefreshGrid, "workers.add.isOpen", action.payload.data);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("workers.add.isLoading", false));
    }
}

function* initWorkerEdit(action: ActionWithDataPathBase<number>) {
    try {
        const { data } = yield call(ajax.get, `/places-workers/${action.payload}`);
        yield call(putNewForm, action.dataPath, new PlacesWorkerForUpdateModel(data));
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "workers.edit", message);
    }
}

function* updateWorker(action: ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>>) {
    try {
        const formData: PlacesWorkerForUpdateModel = yield select((state: AppState) => get(state.forms, action.dataPath));
        yield call(ajax.put, `/places-workers/${action.payload.data.id}`, formData);
        yield call(closeWorkersDialogAndRefreshGrid, "workers.edit.isOpen", action.payload.data.tableDataPath);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("workers.edit.isLoading", false));
    }
}

function* deleteWorker(action: ActionWithDataPathBase<null>) {
    try {
        const { id } = yield select((state: AppState) => state.administration.core.places.workers.delete);
        yield call(ajax.delete, `/places-workers/${id}`);
        yield call(closeWorkersDialogAndRefreshGrid, "workers.delete.isOpen", action.dataPath);
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "workers.delete", message);
    }
    finally {
        yield put(actions.changeValue("workers.delete.isLoading", false));
    }
}

function* closeWorkersDialogAndRefreshGrid(dataPath: string, payload: string) {
    yield all([
        put(actions.changeValue(dataPath, false)),
        put(tableActions.getData(payload, {
            url: `/places-workers`
        }))
    ]);
}

function* initManagementAdd(action: ActionWithDataPathBase<number>) {
    yield call(putNewForm, action.dataPath, new PlacesManagementForCreationModel(action.payload));
}

function* createManagement(action: ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    tableDataPath: string;
}>>) {
    try {
        const formData: PlacesManagementForCreationModel = yield select((state: AppState) => get(state.forms, action.dataPath));
        yield call(ajax.post, `/places/${action.payload.data.id}/management`, formData);
        yield call(closeManagementDialogAndRefreshGrid, "management.add.isOpen", action.payload.data);
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("management.add.isLoading", false));
    }
}

function* initManagementEdit(action: ActionWithDataPathBase<{
    place_id: number;
    id: number
}>) {
    try {
        const { data } = yield call(ajax.get, `/places/${action.payload.place_id}/management/${action.payload.id}`);
        yield call(putNewForm, action.dataPath, new PlacesManagementForUpdateModel(data));
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "management.edit", message);
    }
}

function* updateManagement(action: ActionWithDataPathBase<ActionWithCallback<{
    id: number;
    place_id: number;
    tableDataPath: string;
}>>) {
    try {
        const formData: PlacesManagementForUpdateModel = yield select((state: AppState) => get(state.forms, action.dataPath));
        yield call(ajax.put, `/places/${action.payload.data.place_id}/management/${action.payload.data.id}`, formData);
        yield call(closeManagementDialogAndRefreshGrid, "management.edit.isOpen", {
            id: action.payload.data.place_id,
            tableDataPath: action.payload.data.tableDataPath
        });
    }
    catch (error) {
        if (action.payload.errorCallback) {
            const errors = handleFormErrors(error.response);
            yield call(action.payload.errorCallback, errors);
        }
    }
    finally {
        yield put(actions.changeValue("management.edit.isLoading", false));
    }
}

function* deleteManagement(action: ActionWithDataPathBase<null>) {
    try {
        const { id, place_id } = yield select((state: AppState) => state.administration.core.places.management.delete);
        yield call(ajax.delete, `/places/${place_id}/management/${id}`);
        yield call(closeManagementDialogAndRefreshGrid, "management.delete.isOpen", {
            id: place_id,
            tableDataPath: action.dataPath
        });
    } catch (error) {
        const message = handleRequestErrors(error.response);
        yield call(setDialogErrors, "management.delete", message);
    }
    finally {
        yield put(actions.changeValue("management.delete.isLoading", false));
    }
}

function* closeManagementDialogAndRefreshGrid(dataPath: string, payload: {
    id: number;
    tableDataPath: string;
}) {
    yield all([
        put(actions.changeValue(dataPath, false)),
        put(tableActions.getData(payload.tableDataPath, {
            url: `/places/${payload.id}/management`
        }))
    ]);
}

function* uploadCategoryPhoto(action: ActionWithDataPathBase<{
    id: number;
    photo: File;
}>) {
    try {
        const formToSend = new FormData();
        formToSend.append("photo", action.payload.photo);

        yield call(ajax.post, `/places-categories/${action.payload.id}`, formToSend);
        yield put(tableActions.getData(action.dataPath, {
            url: '/places-categories?append=photo_url'
        }));
    }
    catch (error) {
        if (error.response) {
            yield put(actions.changeValue("document.errors", "something went wrong"));
        } else {
            yield put(actions.changeValue("document.errors", "something went wrong"));
        }
    }
    finally {
        yield put(actions.changeValue("document.isLoading", false));
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.INIT_ADD, initAdd),
        takeEvery(constants.ADD_SUBMIT, create),
        takeEvery(constants.INIT_EDIT, initEdit),
        takeEvery(constants.EDIT_SUBMIT, update),
        takeEvery(constants.DELETE_SUBMIT, deletePlace),
        takeEvery(constants.GET_HOMEPAGE, getHomepage),
        takeEvery(constants.SAVE_HOMEPAGE, updateHomepage),
        takeEvery(constants.INIT_WORKERS_ADD, initWorkersAdd),
        takeEvery(constants.ADD_WORKERS_SUBMIT, createWorker),
        takeEvery(constants.INIT_WORKERS_EDIT, initWorkerEdit),
        takeEvery(constants.EDIT_WORKERS_SUBMIT, updateWorker),
        takeEvery(constants.DELETE_WORKERS_SUBMIT, deleteWorker),
        takeEvery(constants.INIT_MANAGEMENT_ADD, initManagementAdd),
        takeEvery(constants.ADD_MANAGEMENT_SUBMIT, createManagement),
        takeEvery(constants.INIT_MANAGEMENT_EDIT, initManagementEdit),
        takeEvery(constants.EDIT_MANAGEMENT_SUBMIT, updateManagement),
        takeEvery(constants.DELETE_MANAGEMENT_SUBMIT, deleteManagement),
        takeEvery(constants.UPLOAD_CATEGORY_PHOTO, uploadCategoryPhoto)
    ]);
}