export default class InstrumentsHomepageFrontModel {
    introduction: string;
    content: string;
    conclusion: string;

    constructor(obj?: InstrumentsHomepageFrontModel) {
        this.introduction = obj?.introduction;
        this.content = obj?.content;
        this.conclusion = obj?.conclusion;
    }
}