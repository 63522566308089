import { push } from "connected-react-router";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { ActionWithPayloadBase } from "../../actions/actionWithPayloadBase";
import * as constants from "../../constants/utils";
import ajax from "../../util/api";

function* downloadFile(action: ActionWithPayloadBase<string>) {
    try {
        const file = yield call(ajax.get, action.payload, {
            responseType: "blob"
        });
        const blob = new Blob([file.data], { type: file.headers["content-type"] });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        const fileName = getFileName(file.headers["content-disposition"]);
        link.download = fileName;
        link.click();
    } catch (error) {
        if (error.response) {
            switch (error.response.status) {
                case 404:
                    yield put(push("/404"));
                    break;
                case 500:
                default:
                    yield put(push("/500"));
                    break;
            }
        }
        yield put(push("/500"));
    }
}

function getFileName(response: any) {
    const aux = response.substring(response.indexOf("=") + 1);
    return aux.substring(0, aux.indexOf(";"));
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.DOWNLOAD_FILE, downloadFile)
    ]);
}