export default interface User {
    username: string | null;
    access_token: string | null;
    expires_at: Date | null;
}

const userFromStorage = sessionStorage.getItem("crtUser");
let crtUser: User = {
    username: null,
    access_token: null,
    expires_at: null
};
if (userFromStorage) {
    crtUser = JSON.parse(userFromStorage);
}

export const getDefaultUser: User = crtUser;