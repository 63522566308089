import { combineReducers } from "redux";
import instrumentsReducer, { getDefaultInstruments, Instruments } from "./instruments";
import pagesReducer, { getDefaultPages, Pages } from "./pages";
import peopleReducer, { getDefaultPeople, People } from "./people";
import placesReducer, { getDefaultPlaces, Places } from "./places";

export interface Core {
    people: People;
    places: Places;
    instruments: Instruments;
    pages: Pages;
}

export const getDefaultCore: Core = {
    people: getDefaultPeople,
    places: getDefaultPlaces,
    instruments: getDefaultInstruments,
    pages: getDefaultPages
}

export default combineReducers({
    people: peopleReducer,
    places: placesReducer,
    instruments: instrumentsReducer,
    pages: pagesReducer
});