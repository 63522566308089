import { push } from "connected-react-router";
import { all, call, put, takeEvery } from "redux-saga/effects";
import * as constants from "../../constants/terms";
import ajax from "../../util/api";

function* getTerms() {
    try {
        const { data } = yield call(ajax.get, "/terms");
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "content",
            payload: data
        });
    } catch (error) {
        if (error.response) {
            switch (error.status) {
                case 404:
                    yield put(push("/404"));
                    break;
                default:
                    yield put(push("/500"));
                    break;
            }
        } else {
            yield put(push("/500"));
        }
    } finally {
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "isLoading",
            payload: false
        });
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.GET_TERMS, getTerms)
    ]);
}