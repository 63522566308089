export default class PageQuoteForCreationModel {
    picture: File;
    url: string;
    quote: string;
    author: string;
    order_no: number;
    page_id: number;

    constructor(page_id: number) {
        this.page_id = page_id;
    }
}

export class PageQuoteForUpdateModel extends PageQuoteForCreationModel {
    id: number;

    constructor(obj?: PageQuoteForUpdateModel) {
        super(obj?.page_id);
        this.id = obj?.id;
        this.picture = obj?.picture;
        this.url = obj?.url;
        this.quote = obj?.quote;
        this.author = obj?.author;
        this.order_no = obj?.order_no;
    }
}