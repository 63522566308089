const MODULE = "ADMINISTRATION_SECURITY_USERS_";

export const CHANGE_VALUE = `${MODULE}CHANGE_VALUE`;
export type CHANGE_VALUE = typeof CHANGE_VALUE;

export const INIT_ADD = `${MODULE}INIT_ADD`;
export type INIT_ADD = typeof INIT_ADD;

export const ADD_SUBMIT = `${MODULE}ADD_SUBMIT`;
export type ADD_SUBMIT = typeof ADD_SUBMIT;

export const INIT_EDIT = `${MODULE}INIT_EDIT`;
export type INIT_EDIT = typeof INIT_EDIT;

export const EDIT_SUBMIT = `${MODULE}EDIT_SUBMIT`;
export type EDIT_SUBMIT = typeof EDIT_SUBMIT;

export const INIT_CHANGE_PASSWORD = `${MODULE}INIT_CHANGE_PASSWORD`;
export type INIT_CHANGE_PASSWORD = typeof INIT_CHANGE_PASSWORD;

export const CHANGE_PASSWORD_SUBMIT = `${MODULE}CHANGE_PASSWORD_SUBMIT`;
export type CHANGE_PASSWORD_SUBMIT = typeof CHANGE_PASSWORD_SUBMIT;

export const INIT_DELETE = `${MODULE}INIT_DELETE`;
export type INIT_DELETE = typeof INIT_DELETE;

export const DELETE_SUBMIT = `${MODULE}DELETE_SUBMIT`;
export type DELETE_SUBMIT = typeof DELETE_SUBMIT;