import { combineReducers } from "redux";
import { getDefaultPlaces, Places } from "./places";
import peopleReducer, { getDefaultPeople, People } from "./people";
import storiesReducer, { getDefaultStories, Stories } from "./stories";
import storyReducer, { getDefaultStory, Story } from "./story";
import placesReducer from "./places";
import { RepressionActions } from "../../actions/repression";
import * as constants from "../../constants/repression";
import produce from "immer";
import RepressionHomepageModel from "../../models/repression/RepressionHomepageModel";
import { ActionWithDataPathBase } from "../../actions/actionWithDataPathBase";
import set from "lodash/set";
import instrumentsReducer, { getDefaultInstruments, Instruments } from "./instruments";

interface Homepage {
    isLoading: boolean;
    content: RepressionHomepageModel;
}

const getDefaultHomepage: Homepage = {
    isLoading: false,
    content: new RepressionHomepageModel()
}

export interface Repression {
    people: People,
    places: Places,
    stories: Stories;
    story: Story;
    homepage: Homepage;
    instruments: Instruments;
};

export const getDefaultRepression: Repression = {
    people: getDefaultPeople,
    places: getDefaultPlaces,
    stories: getDefaultStories,
    story: getDefaultStory,
    homepage: getDefaultHomepage,
    instruments: getDefaultInstruments
};

const repressionReducer = (state: Homepage = getDefaultHomepage, action: RepressionActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.GET_HOMEPAGE:
            return produce(state, draft => {
                draft.isLoading = true;
            });
        default:
            return state;
    }
}

export default combineReducers({
    people: peopleReducer,
    places: placesReducer,
    stories: storiesReducer,
    story: storyReducer,
    homepage: repressionReducer,
    instruments: instrumentsReducer
});