export default class PlacesManagementForCreationModel {
    last_name: string;
    first_name: string;
    position: string;
    start_year: number;
    end_year: number;
    place_id: number;

    constructor(id: number) {
        this.place_id = id;
    }
}