import { push } from "connected-react-router";
import { all, call, put, takeEvery } from "redux-saga/effects";
import ajax from "../../../util/api";
import * as constants from "../../../constants/repression/instruments";
import InstrumentsHomepageFrontModel from "../../../models/repression/instruments/InstrumentsHomepageFrontModel";

function* getPeople() {
    try {
        const homepage = yield call(ajax.get, "/instruments-homepage");
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "content",
            payload: new InstrumentsHomepageFrontModel(homepage.data)
        });
    } catch (error) {
        if (error.response) {
            switch (error.status) {
                case 404:
                    yield put(push("/404"));
                    break;
                default:
                    yield put(push("/500"));
                    break;
            }
        } else {
            yield put(push("/500"));
        }
    } finally {
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "isLoading",
            payload: false
        });
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.GET_INSTRUMENTS, getPeople)
    ]);
}