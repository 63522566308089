import produce from "immer";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../../../actions/actionWithDataPathBase";
import { ActionWithPayloadBase } from "../../../../actions/actionWithPayloadBase";
import { InstrumentsActions } from "../../../../actions/administration/core/instruments";
import * as constants from "../../../../constants/administration/core/instruments";
import InstrumentsCategories from "../../../../enums/core/InstrumentsCategories";

export interface Instruments {
    homepage: {
        gettingData: boolean;
        isLoading: boolean;
        errors: string;
    },
    add: {
        isOpen: boolean;
        isLoading: boolean;
    },
    edit: {
        gettingData: boolean;
        isLoading: boolean;
        errors: string | null;
    },
    delete: {
        isOpen: boolean;
        isLoading: boolean;
        errors: string | null;
        id: number | null;
    },
    positions: {
        add: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
        },
        edit: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
        },
        delete: {
            isOpen: boolean;
            isLoading: boolean;
            errors: string | null;
            id: number | null;
            instrument_id: number | null;
        }
    }
}

export const getDefaultInstruments: Instruments = {
    homepage: {
        gettingData: false,
        isLoading: false,
        errors: null
    },
    add: {
        isOpen: false,
        isLoading: false
    },
    edit: {
        gettingData: false,
        isLoading: false,
        errors: null
    },
    delete: {
        isOpen: false,
        isLoading: false,
        errors: null,
        id: null
    },
    positions: {
        add: {
            isOpen: false,
            isLoading: false,
            errors: null
        },
        edit: {
            isOpen: false,
            isLoading: false,
            errors: null
        },
        delete: {
            isOpen: false,
            isLoading: false,
            errors: null,
            id: null,
            instrument_id: null
        }
    },
};

const instrumentsReducer = (state: Instruments = getDefaultInstruments, action: InstrumentsActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.INIT_ADD:
            return produce(state, draft => {
                draft.add.isOpen = true;
            });
        case constants.ADD_SUBMIT:
            return produce(state, draft => {
                draft.add.isLoading = true;
            });
        case constants.EDIT_SUBMIT:
            return produce(state, draft => {
                draft.edit.isLoading = true;
            });
        case constants.INIT_DELETE:
            return produce(state, draft => {
                draft.delete.isOpen = true;
                draft.delete.id = (action as ActionWithDataPathBase<{
                    id: number,
                    category: InstrumentsCategories
                }>).payload.id;
            });
        case constants.DELETE_SUBMIT:
            return produce(state, draft => {
                draft.delete.isLoading = true;
            });
        case constants.INIT_POSITIONS_ADD:
            return produce(state, draft => {
                draft.positions.add.isOpen = true;
            });
        case constants.ADD_POSITIONS_SUBMIT:
            return produce(state, draft => {
                draft.positions.add.isLoading = true;
            });
        case constants.INIT_POSITIONS_EDIT:
            return produce(state, draft => {
                draft.positions.edit.isOpen = true;
            });
        case constants.EDIT_POSITIONS_SUBMIT:
            return produce(state, draft => {
                draft.positions.edit.isLoading = true;
            });
        case constants.INIT_POSITIONS_DELETE:
            return produce(state, draft => {
                draft.positions.delete.isOpen = true;
                const { payload } = (action as ActionWithPayloadBase<{
                    instrument_id: number;
                    id: number
                }>);
                draft.positions.delete.id = payload.id;
                draft.positions.delete.instrument_id = payload.instrument_id;
            });
        case constants.DELETE_POSITIONS_SUBMIT:
            return produce(state, draft => {
                draft.positions.delete.isLoading = true;
            });
        case constants.GET_HOMEPAGE:
            return produce(state, draft => {
                draft.homepage.gettingData = true;
            });
        case constants.SAVE_HOMEPAGE:
            return produce(state, draft => {
                draft.homepage.isLoading = true;
            });
        default:
            return state;
    }
}

export default instrumentsReducer;