export default class PlaceWorkerModel {
    convicted: number;
    hospitalized: number;
    name: string;
    total: number;

    constructor(obj?: PlaceWorkerModel) {
        this.convicted = obj?.convicted;
        this.hospitalized = obj?.hospitalized;
        this.name = obj?.name;
        this.total = obj?.total;
    }
}