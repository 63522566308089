import set from "lodash/set";
import produce from "immer";
import { FormsActions } from "../../actions/forms";
import * as constants from "../../constants/forms";
import { ActionWithDataPathBase } from "../../actions/actionWithDataPathBase";
import { ActionWithPayloadBase } from "../../actions/actionWithPayloadBase";

export interface Forms {
    [key: string]: any;
}

export const getDefaultForms: Forms = {

};

const formsReducer = (state: Forms = getDefaultForms, action: FormsActions): Forms => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.REMOVE_FORM:
            return produce(state, draft => {
                const { payload } = (action as ActionWithPayloadBase<string>);
                delete draft[payload];
            });
        default:
            return state;
    }
}

export default formsReducer;