import { push } from "connected-react-router";
import map from "lodash/map";
import { all, call, put, takeEvery } from "redux-saga/effects";
import * as constants from "../../../constants/repression/stories";
import StoryItem from "../../../models/repression/storyItem";
import ajax from "../../../util/api";

function* getStories() {
    try {
        const { data } = yield call(ajax.get, "/people?append=story_url&filter[is_published]=true&filter[show_in_slider]=true&sort=last_name,first_name");
        if (data && data.data) {
            yield put({
                type: constants.CHANGE_VALUE,
                dataPath: "items",
                payload: map(data.data, (el: any) => new StoryItem(el))
            });
        }
    } catch (error) {
        if (error.response) {
            switch (error.status) {
                case 404:
                    yield put(push("/404"));
                    break;
                default:
                    yield put(push("/500"));
                    break;
            }
        } else {
            yield put(push("/500"));
        }
    } finally {
        yield put({
            type: constants.CHANGE_VALUE,
            dataPath: "isLoading",
            payload: false
        });
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.GET_STORIES, getStories)
    ]);
}