import { produce } from "immer";
import set from "lodash/set";
import { ActionWithDataPathBase } from "../../../actions/actionWithDataPathBase";
import { RepressionInstrumentsActions } from "../../../actions/repression/instruments";
import InstrumentsHomepageFrontModel from "../../../models/repression/instruments/InstrumentsHomepageFrontModel";
import * as constants from "../../../constants/repression/instruments";

export interface Instruments {
    isLoading: boolean;
    content: InstrumentsHomepageFrontModel;
}

export const getDefaultInstruments: Instruments = {
    isLoading: false,
    content: null
}

const instrumentsReducer = (state: Instruments = getDefaultInstruments, action: RepressionInstrumentsActions) => {
    switch (action.type) {
        case constants.CHANGE_VALUE:
            return produce(state, draft => {
                const { dataPath, payload } = (action as ActionWithDataPathBase<any>);
                set(draft, dataPath, payload);
            });
        case constants.GET_INSTRUMENTS:
            return produce(state, draft => {
                draft.isLoading = true;
            });
        default:
            return state;
    }
}

export default instrumentsReducer;