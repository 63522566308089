export type PeopleForCreationModel = {
    last_name: string;
    first_name: string;
    date_of_birth: string;
}

export const getDefaultPeopleForCreation: PeopleForCreationModel = {
    last_name: "",
    first_name: "",
    date_of_birth: null
}