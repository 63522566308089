import { all, call, put, select, takeEvery } from "redux-saga/effects";
import get from "lodash/get";
import formDef, { ILoginForm } from "../../../models/auth/login/ILoginForm";
import * as constants from "../../../constants/auth/login";
import { putNewForm } from "../../forms";
import { ActionWithPayloadBase } from "../../../actions/actionWithPayloadBase";
import * as authActions from "../../../actions/auth";
import * as actions from "../../../actions/auth/login";
import { AppState } from "../../../store/appState";
import { push } from "connected-react-router";
import ajax from "../../../util/api";
import { FORM_ERROR } from "final-form";
import { ActionWithCallback } from "../../../actions/actionWithCallback";

function* initForm(action: ActionWithPayloadBase<string>) {
    const dataPath = yield call(putNewForm, action.payload, formDef);
    yield put({
        type: constants.CHANGE_VALUE,
        dataPath: "dataPath",
        payload: dataPath
    });
}

function* login(action: ActionWithPayloadBase<ActionWithCallback<string>>) {
    try {
        const formData: ILoginForm = yield select((state: AppState) => get(state.forms, action.payload.data));
        const { data } = yield call(ajax.post, "/auth/login", formData);
        sessionStorage.setItem("crtUser", JSON.stringify(data));
        yield all([
            put(authActions.setUser({
                username: data.username,
                access_token: data.access_token,
                expires_at: data.expires_at
            })),
            put(actions.changeValue("isLoading", false)),
            put(push('/administration'))
        ]);
    }
    catch (error) {
        yield put(actions.changeValue("isLoading", false));
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    if (action.payload.errorCallback) {
                        yield call(action.payload.errorCallback, {
                            [FORM_ERROR]: error.response.data.message
                        });
                    }
                    break;
                default:
                    if (action.payload.errorCallback)
                        yield call(action.payload.errorCallback, {
                            [FORM_ERROR]: "something went wrong"
                        });
                    break;
            }
        }
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(constants.INIT_FORM, initForm),
        takeEvery(constants.SUBMIT, login)
    ]);
}