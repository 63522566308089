const MODULE = "SETTINGS_";

export const TOGGLE_COLLAPSED_NAV = `${MODULE}toggle_collapse_menu`;
export type TOGGLE_COLLAPSED_NAV = typeof TOGGLE_COLLAPSED_NAV;

export const WINDOW_WIDTH = `${MODULE}window-width`;
export type WINDOW_WIDTH = typeof WINDOW_WIDTH;

export const SWITCH_LANGUAGE = `${MODULE}switch-language`;
export type SWITCH_LANGUAGE = typeof SWITCH_LANGUAGE;

export const CHANGE_NAVIGATION_STYLE = `${MODULE}change-navigation-style`;
export type CHANGE_NAVIGATION_STYLE = typeof CHANGE_NAVIGATION_STYLE;

export const HORIZONTAL_MENU_POSITION = `${MODULE}horizontal-menu-position`;
export type HORIZONTAL_MENU_POSITION = typeof HORIZONTAL_MENU_POSITION;

export const THEME_COLOR = `${MODULE}theme_color`;
export type THEME_COLOR = typeof THEME_COLOR;

export const DARK_THEME = `${MODULE}dark_theme`;
export type DARK_THEME = typeof DARK_THEME;

export const DRAWER_TYPE = `${MODULE}drawer_type`;
export type DRAWER_TYPE = typeof DRAWER_TYPE;